export const APP_URL = window.location.origin;
export const LOGIN_URL = "/login";
export const DASHBOARD_URL = "/dashboard";
export const LOGO_URL = window.location.origin + "/logo.svg";
export const USER_URL = "/user";
export const CHAT_URL = "/chat";
export const DEVICES = "/devices";
export const INVOICES = "/invoice";
export const GAMES = "/games";
export const CUSTOMER = "/customer";

export const API_URL = process.env.REACT_APP_API_URL;
// export const LOGIN_API_URL = process.env.REACT_APP_API_URL + "user/register"; Old API
// export const SEND_OTP_API_URL = process.env.REACT_APP_API_URL + "user/otp"; Old API
export const LOGIN_API_URL = process.env.REACT_APP_API_URL + "superadmin/login"; // New API
export const SEND_OTP_API_URL = process.env.REACT_APP_API_URL + "user/otp"; // New API
export const GET_USER_API_URL = process.env.REACT_APP_API_URL + "user/get";
export const GET_USER_LIST_API_URL = process.env.REACT_APP_API_URL + "superadmin/get-user-list/";
export const ADD_CHAT = process.env.REACT_APP_API_URL + "superadmin/add-chat";
export const ADD_NEW_USER = process.env.REACT_APP_API_URL + "superadmin/add-user";
export const GET_SUPERUSER_LIST_API_URL = process.env.REACT_APP_API_URL + "superadmin/get-superuser-list";
export const ASSIGN_ROLE_API_URL = process.env.REACT_APP_API_URL + "superadmin/assign-role";
export const COUNT_API_URL = process.env.REACT_APP_API_URL + "superadmin/counts/";
export const ADD_CHAT_API_URL = process.env.REACT_APP_API_URL + "superadmin/add-chat";
export const UPLOAD_FILE_URL = process.env.REACT_APP_API_URL + "superadmin/upload-file";
export const ACTIVE_SESSION_LIST = process.env.REACT_APP_API_URL + "superadmin/session-list";
export const DEAACTIVE_TOKEN = process.env.REACT_APP_API_URL + "superadmin/deactivate-token";
export const GET_NOTIFICATION_LIST = process.env.REACT_APP_API_URL + "superadmin/get-notifications/";
export const ADD_NOTIFICATION = process.env.REACT_APP_API_URL + "superadmin/add-notification";
export const EDIT_NOTIFICATION = process.env.REACT_APP_API_URL + "superadmin/update-notification";
export const DELETE_NOTIFICATION = process.env.REACT_APP_API_URL + "superadmin/delete-notification";
export const SEND_SYSTEM_NOTIFICATION = process.env.REACT_APP_API_URL + "firebase/sys-notification/";
export const INVOICE_LIST = process.env.REACT_APP_API_URL + "superadmin/invoice-list/";
export const CUSTOMER_LIST = process.env.REACT_APP_API_URL + "superadmin/customer-list/";
export const GET_GAMES_LIST = process.env.REACT_APP_API_URL + "superadmin/get-game-list/";
export const ADD_GAME = process.env.REACT_APP_API_URL + "superadmin/add-game";
export const EDIT_GAME = process.env.REACT_APP_API_URL + "superadmin/update-game";
export const DELETE_GAME = process.env.REACT_APP_API_URL + "superadmin/delete-game/";
export const GET_PERMISTIONS = process.env.REACT_APP_API_URL + "superadmin/user/permissions";