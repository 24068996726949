/* eslint-disable */
import React, { useState, useEffect } from "react";
import Layout from "../Shared/Layout";
import { CUSTOMER_LIST } from '../Shared/Constant';
import { Pagination } from "react-pagination-bar";
import { useSelector, useDispatch} from 'react-redux';
import { getCustomerList } from "../../redux/actions";
import Tooltip from "../Common/tooltip";
import PreviewIcon from "../../assets/img/preview.png";
import moment from "moment/moment";
import CommonPreviewPopup from "../Common/CommonPreviewPopup";
import Invoice from "../Invoice/invoice";

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const Customer = () => {
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const Customerslist = useSelector((state) => state.allReducers.customerslists);
    const dispatch = useDispatch();
    const [ SearchBox, setSearchBox ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ UserListSelect, setUserListSelect ] = useState("all");
    const [ selectUserLimit, setSelectUserLimit ] = useState(10);
    const [ selectCustomerDetails, setSelectCustomerDetails ] = useState({});

    useEffect(() => {
        customerFilter("all", 1, selectUserLimit);
    }, [selectUserLimit, UserListSelect, SearchBox]);

    // current page select
    const currentFunction = (pageNumber) => {
        return setCurrentPage(pageNumber, customerFilter("all", pageNumber, selectUserLimit));      
    };

    // select limit
    const SelectLimit = (limits) => {
        setSelectUserLimit(limits);
        setCurrentPage(1);
    };

    // get customer list
    const customerFilter = (type, currentPages, limit) => {       
        window.axios.get(CUSTOMER_LIST+`${limit}/${currentPages}/${SearchBox === "" ? "none": SearchBox}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(getCustomerList(result?.data?.data))
                setSelectCustomerDetails(result?.data?.data[0]);
                setPaginationLength(result.data.pagination);
            }
        }).catch(function (error) {
            if(error) {
                dispatch(getCustomerList([]))
            }
        });
    }

    // preview details in popup
    const PreviewCustomer = (data) => {
        setSelectCustomerDetails(data);
    };
    
    return( <Layout>
        <div className="col-lg-6">
            <div className="customerscolswraps">
                <div className="card" >
                    <div className="card-header border-0">
                        <div className="card-title">Customer list :</div>
                        <div className='cradRightsection'>
                            <input type="text" className="form-filter ms-3" value={SearchBox} onChange={(e) => setSearchBox(e.target.value)} placeholder='Search Customer Details'/>
                            <select defaultValue="Select Limit" className="col-6-form-select ms-3" onChange={(e) => SelectLimit(e.target.value)} aria-label="Default select example">
                                <option value="Select Limit" >Select Limit</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="80">90</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>

                    <div className="card-table table-responsive">
                        <table className="table tableInvoice table-vcenter">
                            <thead>
                                <tr>
                                    {/* <th>Sr.No</th> */}
                                    <th>Customer Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Customerslist && Customerslist.length > 0 ? Customerslist.map((elm, index) => {
                                    return(<tr className={elm?.id === selectCustomerDetails.id ? "active" : ""} key={index} onClick={() => PreviewCustomer(elm)}>
                                        {/* <td>{((paginationLength.current_page - 1) * 10)+ index +1}</td> */}
                                        <td>{elm.customer_name === "" ? "-" : elm.customer_name}</td>
                                        <td>{elm.email}</td>
                                        <td>{elm.phone === "" ? "-" : elm.phone}</td>
                                    </tr>)
                                }) : (<tr>
                                    <td>No data found</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='paginationCustom'>
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={paginationLength?.record_limit}
                            onPageChange={(pageNumber) => currentFunction(pageNumber)}
                            totalItems={paginationLength?.total_records}
                            pageNeighbours={2}
                        />
                </div>
            </div>
        </div>
        <div className="col-lg-6" style={{ flexDirection: "column" }}>
            <div className="card" style={{ marginBottom: "20px", maxHeight: "130px" }}>
                <div className="card-header border-0">
                    <div className="card-title">Customer Details : </div>
                </div>
                <div className="carddetailswraps">
                    <div className="cardheaders">
                        <ul>
                            <li><label>Customer Name: </label> {selectCustomerDetails?.customer_name !== "" ? selectCustomerDetails?.customer_name : "-" }</li>
                            <li><label>Company Name: </label> {selectCustomerDetails?.company_name !== "" ? selectCustomerDetails?.company_name : "-"}</li>
                            {/* <li><label>Email: </label> {selectCustomerDetails.length === 0 ? "-" : selectCustomerDetails[0]?.customer_details?.email}</li>
                            <li><label>Phone: </label> {selectCustomerDetails.length === 0 ? "-" : selectCustomerDetails[0]?.customer_details?.phone}</li>  */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="invoicelistaddedcustomer">
                <Invoice from={"Customer"} invoiceid={selectCustomerDetails && selectCustomerDetails?.id}/>
            </div>
        </div>
        

        {/* popup details preview */}
        {/* {popupDetails.OpenModal && <CommonPreviewPopup popupDetails={popupDetails} setpopupDetails={setpopupDetails} />} */}
        {/* {popupDetails.OpenModal && (<div className='modalBckground' onClick={() => setpopupDetails({...popupDetails, OpenModal: false})}></div>)} */}
    </Layout>)
}

export default Customer;