/*eslint-disable */
import React from 'react';
import { LOGO_URL, DASHBOARD_URL, LOGIN_URL, USER_URL, CHAT_URL, DEVICES, INVOICES, GAMES, CUSTOMER } from '../Shared/Constant';
import PreviewImage from "../../Components/Common/PreviewImageFiles";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, CleanViewBaseURL } from '../../redux/actions';
import DevicesIcons from '../../assets/img/devices_icons.png';
import InvoiceIcons from '../../assets/img/invoice.png';
import GamesIcons from '../../assets/img/console.png';
import CustomerIcons from '../../assets/img/customer.png';

const Header = (props) => {
    const { ProfileData } = props;
    let CurrentPath = window.location.pathname;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    // logout function
    const handleLogout = () => {
        localStorage.removeItem("access_token");
        dispatch(logoutUser());
        dispatch(CleanViewBaseURL());
        setTimeout(()=>navigate(LOGIN_URL), 100);
    }

    return (
        <>
            <header className="navbar headerDashboard navbar-expand-md navbar-light d-print-none">
                <div className="container-xl">
                    <ul className="navbar-nav  flex-row order-md-first">
                        <li className="nav-item">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                                <a href={DASHBOARD_URL}>
                                    <img 
                                        src={LOGO_URL}  
                                        style={{
                                            width: "32px",
                                            height: "32px",
                                        }}
                                        alt="NW Admin Panel" 
                                        className="navbar-brand-image" 
                                    />
                                </a>
                            </h1>
                        </li>
                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("dashboard")) && (<li className={CurrentPath == DASHBOARD_URL ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                            <Link className="nav-link" to={DASHBOARD_URL} >
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="5 12 3 12 12 3 21 12 19 12" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                                </span>
                                <span className="nav-link-title">
                                    Home
                                </span>
                            </Link>
                        </li>)}
                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("add-user") || ProfileData?.permission?.includes("user-list") || ProfileData?.permission?.includes("update-user"))) && (<li className={CurrentPath == USER_URL ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                            <Link className="nav-link" to={USER_URL} >
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width="44" height="44" viewBox="0 0 24 24" stroke="currentColor" fill="none" >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="9" cy="7" r="4" />
                                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                    </svg>
                                </span>
                                <span className="nav-link-title">
                                    User
                                </span>
                            </Link>
                        </li>)}
                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("add-chat") || ProfileData?.permission?.includes("notification-list") || ProfileData?.permission?.includes("add-notification") || ProfileData?.permission?.includes("update-notification") || ProfileData?.permission?.includes("delete-notification"))) && (<>
                            <li className={CurrentPath == CHAT_URL ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                                <Link className="nav-link" to={CHAT_URL} >
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle-2" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> 
                                            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                        </svg>
                                    </span>
                                    <span className="nav-link-title">
                                        Chat
                                    </span>
                                </Link>
                            </li>
                        </>)}
                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("add-invoice") || ProfileData?.permission?.includes("invoice-list") || ProfileData?.permission?.includes("view-invoice"))) && (<li className={CurrentPath == INVOICES ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                            <Link className="nav-link" to={INVOICES} >
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <img className="icon icon-tabler icon-tabler-users" src={InvoiceIcons} alt="DevicesIcons" width="44" height="44" viewBox="0 0 24 24" stroke="currentColor" fill="none" />
                                </span>
                                <span className="nav-link-title">
                                    Invoice List
                                </span>
                            </Link>
                        </li>)}
                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("customer-list"))) && (<li className={CurrentPath == CUSTOMER ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                            <Link className="nav-link" to={CUSTOMER} >
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <img className="icon icon-tabler icon-tabler-users" src={CustomerIcons} alt="DevicesIcons" width="44" height="44" viewBox="0 0 24 24" stroke="currentColor" fill="none" />
                                </span>
                                <span className="nav-link-title">
                                    Customer List
                                </span>
                            </Link>
                        </li>)}
                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("game-list") || ProfileData?.permission?.includes("update-game") || ProfileData?.permission?.includes("add-game") || ProfileData?.permission?.includes("delete-game"))) && (<li className={CurrentPath == GAMES ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                            <Link className="nav-link" to={GAMES} >
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <img className="icon icon-tabler icon-tabler-users" src={GamesIcons} alt="GamesIcons" width="44" height="44" viewBox="0 0 24 24" stroke="currentColor" fill="none" />
                                </span>
                                <span className="nav-link-title">
                                    Games
                                </span>
                            </Link>
                        </li>)}
                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("session-list") || ProfileData?.permission?.includes("session-deactivate"))) && (<li className={CurrentPath == DEVICES ? "nav-item active expand-hide" : "nav-item expand-hide"}>
                            <Link className="nav-link" to={DEVICES} >
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <img className="icon icon-tabler icon-tabler-users" src={DevicesIcons} alt="DevicesIcons" width="44" height="44" viewBox="0 0 24 24" stroke="currentColor" fill="none" />
                                </span>
                                <span className="nav-link-title">
                                    Devices
                                </span>
                            </Link>
                        </li>)}
                    </ul>
                    <div className="navbar-nav flex-row order-md-last">
                        <div className="nav-item dropdown">
                            <a className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                                {ProfileData?.view_file_url && <PreviewImage type="image" url={ProfileData.view_thumbnail_url} title={name} />}
                                <div className="d-none d-xl-block ps-2">
                                    {ProfileData?.name !== undefined && <div className='profileName'>{ProfileData.name}</div>}
                                    <div className="admintitle mt-1 small text-muted">Admin</div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <a onClick={() => handleLogout()} className="dropdown-item">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div className="navbar-expand-md expand-show">
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <HomeLink href={DASHBOARD_URL} className="nav-item" title="Home" />
                                <UserLink href={USER_URL} className="nav-item" title="User" />
                                <ChatLink href={CHAT_URL} className="nav-item" title="Chat" />
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Header;