/* eslint-disable */
import React, { useState } from "react";
import DeletIcon from "../../assets/img/deletepopup.png";
import ContentEditableCommon from "./ContentEditableCommon";
import CloseIcon from "../../assets/img/closebutton.png";
import { addNotifications, editNotificationsFnt, updateUserList, AddNewUser, deleteNotifications, getAllGames, getAllNotifications, addGames, deleteGames, editGamesFunct } from "../../redux/actions/index";
import { ADD_NOTIFICATION, EDIT_NOTIFICATION, ADD_NEW_USER, ASSIGN_ROLE_API_URL, DELETE_NOTIFICATION, GET_NOTIFICATION_LIST, ADD_GAME, DELETE_GAME, EDIT_GAME, GET_GAMES_LIST } from '../Shared/Constant';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Multiselect from 'multiselect-react-dropdown';
import CountryMobileInput from '../Common/CountrySelectInput/CountryMobileInput';

const CommonPopup = (props) => {
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const Permissionlist = useSelector((state) => state.allReducers.permissionlits);
    const { title, submitbuttontitle, popuptitle, setPopuptitle, setOpenModal, editNotification, editGamesState, seteditNotification, DeleteId, setPaginationLength } = props;
    const [ selectedValueUser, setselectedValueUser ] = useState((popuptitle?.UserData?.permission === null || popuptitle?.UserData?.permission === undefined) ? [] : popuptitle?.UserData?.permission?.split(","));
    const [ currentValue, setCurrentValue ] = useState("");
    const [ GamesValue, setGamesValue ] = useState({
        id: editGamesState?.id,
        game: editGamesState?.game,
        company: editGamesState?.company,
    });
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "+1",
        number: "",
    });
    const [ loader, setLoder ] = useState(false);

    const [ NameUser, setNameUser ] = useState("");
    const [ Operator, setOperator ] = useState(false);
    const [ Superuser, setSuperuser ] = useState(false);
    const [ Staff, setStaff ] = useState(false);
    const [ SoftwareVendor, setSoftwareVendor ] = useState(false);
    const [ MarketingVendor, setMarketingVendor ] = useState(false);

    const [ errorName, setErrorName ] = useState("");
    const [ errorPermission, setErrorPermission ] = useState("");
    const [ errorMobileNo, setErrorMobileNo ] = useState("");

    const dispatch = useDispatch();

    // close modal function
    const closeModal = () => {
        setOpenModal(false);    
        setselectedValueUser([]);
        setPopuptitle({});
    };

    // send message textfield clear function
    const TextFieldMessageClear = () => {
        const cmntMsg = document.getElementById('addNotification');
        cmntMsg.innerHTML = null;
        setTimeout(() => {
            const child = cmntMsg.lastElementChild;
            while (child) {
                cmntMsg.removeChild(child);
                child = cmntMsg.lastElementChild;
            }
        }, 100);
    };

    // select permsissions
    const CategoryIDGetNew = [];
    const onSelectUser = (selectedList, selectedItem) => {
        
        selectedList.filter((elm) => {
            if(elm === "add-user" || elm === "update-user") {
                return CategoryIDGetNew.push(elm,"user-list");
            } else if (elm === "add-invoice" || elm === "view-invoice") {
                return CategoryIDGetNew.push(elm,"invoice-list");
            } else if (elm === "add-game" || elm === "update-game" || elm === "delete-game") {
                return CategoryIDGetNew.push(elm,"game-list");
            } else if (elm === "add-notification" || elm === "update-notification" || elm === "delete-notification") {
                return CategoryIDGetNew.push(elm,"notification-list");
            } else if (elm === "session-deactivate") {
                return CategoryIDGetNew.push(elm,"session-list");
            } else {
                return CategoryIDGetNew.push(elm);
            }
        });
        var unique = CategoryIDGetNew.filter(onlyUnique);
        function onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }
        setselectedValueUser(unique);
    }

    // remove permsissions
    const onRemoveUser = (selectedList, removedItem) => {
        selectedList.filter((elm) => {
            CategoryIDGetNew.push(elm);
        });
        setselectedValueUser(selectedList);
    }

    const ToggleSwitchBtn = (e, types) => {
        if(types === "Operator") {
            setOperator(e.target.checked);
        } else if (types === "Superuser") {
            setSuperuser(e.target.checked)
        } else if (types === "Staff") {
            setStaff(e.target.checked)
        } else if (types === "Software_Vendor") {
            setSoftwareVendor(e.target.checked)        
        } else {
            setMarketingVendor(e.target.checked);
        }
    };

    // add user function
    const AddUserFnct = () => {
        if(Staff) {
            if(NameUser !== "" && MobileNo.number !== "" && selectedValueUser.toString() !== "") {
                // setValidField(false);
                setErrorName("");
                setErrorPermission("");
                if(MobileNo.number.length === 10) {
                    setErrorMobileNo("");
                    setLoder(true);
                    const permissionuserJson = JSON.stringify({ 
                        name: NameUser,
                        phone: MobileNo.number,
                        phone_code: MobileNo.countrycode,
                        is_operator: Operator,
                        is_superuser: Superuser,
                        is_staff: Staff,
                        is_software_vendor: SoftwareVendor,
                        is_marketing_vendor: MarketingVendor,
                        permission: selectedValueUser.toString() === "" ? "none" : selectedValueUser.toString()
                     });
                     window.axios.post(ADD_NEW_USER, permissionuserJson, {
                         headers: {
                             'Content-Type': 'application/json',
                             'Accept': 'application/json',
                             'Authorization': 'Bearer ' + accessToken
                         }
                     }).then(function (result) {
                         if (result?.data?.data) {
                             setTimeout(() => {
                                 dispatch(AddNewUser(result.data.data));
                                 toast.success(result?.data?.msg);
                                 closeModal();
                                 setLoder(false);
                             }, 200);
                         }
                     }).catch(function (result) {
                         toast.error(result?.response?.data?.error);
                         setLoder(false);
                     })
                } else {
                    // setValidField(true);
                    setErrorMobileNo("Please enter 10 digit number");
                }
            } else {
                // setValidField(true);
                setErrorName("Please enter full name");
                setErrorMobileNo("Please enter mobile number");
                setErrorPermission("Please select permissions");
            }
        } else {
            if(NameUser !== "" && MobileNo.number !== "") {
                // setValidField(false);
                setErrorName("");
                setErrorPermission("");
                if(MobileNo.number.length === 10) {
                    setErrorMobileNo("");
                    setLoder(true);
                    const permissionuserJson = JSON.stringify({ 
                        name: NameUser,
                        phone: MobileNo.number,
                        phone_code: MobileNo.countrycode,
                        is_operator: Operator,
                        is_superuser: Superuser,
                        is_staff: Staff,
                        is_software_vendor: SoftwareVendor,
                        is_marketing_vendor: MarketingVendor,
                        permission: selectedValueUser.toString() === "" ? "none" : selectedValueUser.toString()
                     });
                     window.axios.post(ADD_NEW_USER, permissionuserJson, {
                         headers: {
                             'Content-Type': 'application/json',
                             'Accept': 'application/json',
                             'Authorization': 'Bearer ' + accessToken
                         }
                     }).then(function (result) {
                         if (result?.data?.data) {
                             setTimeout(() => {
                                 dispatch(AddNewUser(result.data.data));
                                 toast.success(result?.data?.msg);
                                 closeModal();
                                 setLoder(false);
                             }, 200);
                         }
                     }).catch(function (result) {
                         toast.error(result?.response?.data?.error);
                         setLoder(false);
                     })
                } else {
                    // setValidField(true);
                    setErrorMobileNo("Please enter 10 digit number");
                }
            } else {
                // setValidField(true);
                setErrorName("Please enter full name");
                setErrorMobileNo("Please enter mobile number");
            }
        }
    };

    document.getElementById('inputMobileNo')?.addEventListener("keyup", function(e) {
        if(e.target.value === "") {
            setErrorMobileNo("Please enter mobile number");
        } else {
            console.log(e.target.value.length !== 10)
            if (e.target.value.length !== 10) {
                setErrorMobileNo("Please enter 10 digit number");
            } else {
                setErrorMobileNo("");
            }
        }
    });

    // permission update to user
    const EditUserListf = () => {
        if(selectedValueUser.toString() !== "") {
            setLoder(true);
            const permissionuserJson = JSON.stringify({ user_key: "staff", user_value: 1,permission: selectedValueUser.toString(), user_id: popuptitle?.UserData?.id });
            window.axios.post(ASSIGN_ROLE_API_URL, permissionuserJson, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(function (result) {
                if (result?.data?.data) {
                    setTimeout(() => {
                        dispatch(updateUserList(popuptitle?.UserData?.id, result.data.data));
                        toast.success("Permission Updated Successfully...");
                        setLoder(false);
                        closeModal();
                    }, 200);
                }
            }).catch(function (result) {
                toast.error("Permission Updated not Successfully...");
            })
        } else {
            toast.error("Please select permission...");
        }
    };

    // add notification function
    const AddNotifications = () => {
        if(currentValue !== "") {
            if(title === "Edit Notification") {
                setLoder(true);
                const jsonData1 = JSON.stringify({ 
                    id: editNotification.id,
                    message: currentValue, 
                });
                
                window.axios.post( EDIT_NOTIFICATION, jsonData1, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if (result.data) {
                        setTimeout(() => {
                            dispatch(editNotificationsFnt(result.data.data));
                            toast.success("Notification Edit Successfully...");
                            setLoder(false);
                            setOpenModal(false);
                            TextFieldMessageClear();
                            seteditNotification({
                                ...editNotification,
                                id: "",
                                message: "",
                            });
                        }, 500);
                    }
                }).catch(function (result) {
                    setTimeout(() => {
                        toast.error(result.response.data.error);
                    }, 500);
                });
            } else if(title === "Add Notification") {
                setLoder(true);
                const jsonData1 = JSON.stringify({ 
                    message: currentValue, 
                });
                
                window.axios.post( ADD_NOTIFICATION, jsonData1, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if (result.data) {
                        setTimeout(() => {
                            dispatch(addNotifications(result.data.data));
                            toast.success("Notification add Successfully...");
                            GetUpdateList();
                            setLoder(false);
                            setOpenModal(false);
                            TextFieldMessageClear();
                        }, 500);
                    }
                }).catch(function (result) {
                    setTimeout(() => {
                        toast.error(result.response.data.error);
                        TextFieldMessageClear();
                        setLoder(false);
                    }, 500);
                });
            } 
        } else {
            toast.error("Please enter title");
        }
    };

    // add game and edit game
    const AddGamesList = () => {
        if(GamesValue.game !== "" && GamesValue.company !== "") {
            if (title === "Add Game") {
                setLoder(true);
                const jsonData1 = JSON.stringify({ 
                    game_name: GamesValue.game, 
                    company: GamesValue.company,
                });
                
                window.axios.post( ADD_GAME, jsonData1, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if (result.data) {
                        setTimeout(() => {
                            dispatch(addGames(result.data));
                            toast.success("Game add Successfully...");
                            setLoder(false);
                            setOpenModal(false);
                            setGamesValue({...GamesValue, game: "", company: ""});
                        }, 500);
                    }
                }).catch(function (result) {
                    setTimeout(() => {
                        toast.error(result.response.data.error);
                    }, 500);
                });
            } else if (title === "Edit Game") {
                setLoder(true);
                const jsonData1 = JSON.stringify({ 
                    game_id: editGamesState.id, 
                    game_name: GamesValue.game, 
                    company: GamesValue.company,
                });
                
                window.axios.post( EDIT_GAME, jsonData1, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if (result.data) {
                        setTimeout(() => {
                            dispatch(editGamesFunct(result.data));
                            toast.success("Game Edit Successfully...");
                            setLoder(false);
                            setOpenModal(false);
                            setGamesValue({...GamesValue, game: "", company: ""});
                        }, 500);
                    }
                }).catch(function (result) {
                    setTimeout(() => {
                        toast.error(result.response.data.error);
                    }, 500);
                });
            }
        } else {
            if(GamesValue.game === "") {
                toast.error("Please enter game name");
            } else {
                toast.error("Please enter company name");
            }
        }
    }

    // delete notification functions
    const DeleteNotificationFunct = () => {
        setLoder(true);
        if(title === "Delete Notification") {
            window.axios.get(DELETE_NOTIFICATION + `/${DeleteId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                if (result.data) {
                    setTimeout(() => {
                        dispatch(deleteNotifications(result.data));
                        GetUpdateList();
                        toast.success("Notification Deleted Successfully...");
                        setLoder(false);
                        setOpenModal(false);
                    }, 500);
                }
            }).catch(function (result) {
                setTimeout(() => {
                    toast.error(result.response.data.error);
                }, 500);
            });
        } else {
            window.axios.get(DELETE_GAME + `${DeleteId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                if (result.data) {
                    setTimeout(() => {
                        dispatch(deleteGames(result.data));
                        GetUpdateList();
                        toast.success("Game Deleted Successfully...");
                        setLoder(false);
                        setOpenModal(false);
                    }, 500);
                }
            }).catch(function (result) {
                setTimeout(() => {
                    toast.error(result.response.data.error);
                }, 500);
            });
        }
    };

    // get Notification list
    const GetUpdateList = () => {
        if(title === "Delete Notification" || title === "Add Notification") {
            window.axios.get(GET_NOTIFICATION_LIST+`none/10/1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(function (result) {
                if (result.data) {
                    dispatch(getAllNotifications(result.data.data));
                    setPaginationLength(result.data.pagination);
                }
            }).catch(function (result) {
                console.log("notifaction error........")
            });
        } else {
            window.axios.get(GET_GAMES_LIST+`10/1/none`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(function (result) {
                if (result.data) {
                    dispatch(getAllGames(result.data.data));
                    setPaginationLength(result.data.pagination);
                }
            }).catch(function (result) {
                console.log("notifaction error........")
            });
        }
    };

    return(<div className={(title === "Delete Notification" || title === "Delete Game") ? "commonpopup deletcomonpop" : title === "Add User" ? "commonpopup addUserWrapper" : title === "Add User Permission" ? "commonpopup addUserWrapperPermission" : "commonpopup"}>
        {title === "Delete Notification" || title === "Delete Game" ? (<React.Fragment>
            <div className="Deletemodal">
                <img src={DeletIcon} className="deleteimage" alt="delete" />
                <h4>{title}</h4>
                <p>Are you sure want delete {title === "Delete Game" ? "game" : "notification"} ?</p>
            </div>
            <div className="commonpopupfooter deletfooterwrap">
                <button type="button" className="commonsbtn" onClick={DeleteNotificationFunct}>
                    {loader && <span className="loadersendbtns"></span>}
                    {submitbuttontitle}
                </button>
                <button type="button" className="commoncancelbtn" onClick={closeModal}>Cancel</button>
            </div>
        </React.Fragment>) : (<React.Fragment>
                <div className="commonpopupheader">
                <h4>{title}</h4>
                <button className="closebutton" onClick={closeModal}>
                    <img src={CloseIcon} alt="close button" />
                </button>
            </div>
            <div className="commonpopupBox">
                {(title === "Add Game" || title === "Edit Game") ? (<React.Fragment>
                    <div className="formCustomUi-group edit">
                        <input type="text" className="addgametextfield" id="gamename" placeholder="Enter Game name" value={GamesValue.game} onChange={(e) => setGamesValue({...GamesValue, game: e.target.value})}/>
                        <label>Game Name</label>
                    </div>
                    <div className="formCustomUi-group">
                        <input type="text" className="addgametextfield" placeholder="Enter Company name" value={GamesValue.company} onChange={(e) => setGamesValue({...GamesValue, company: e.target.value})}/>
                        <label>Company Name</label>
                    </div>
                </React.Fragment>) : (title === "Add User Permission") ? (<React.Fragment>
                    <div className="formCustomUi-group edit">
                        <div className="filedsearchReltive">
                                <Multiselect
                                    options={Permissionlist} // Options to display in the dropdown
                                    selectedValues={selectedValueUser} // Preselected value to persist in dropdown
                                    onSelect={onSelectUser} // Function will trigger on select event
                                    onRemove={onRemoveUser} // Function will trigger on remove event
                                    displayValue={"value"} // Property name to display in the dropdown options
                                    singleSelect={false} // only single category select / for multiple category select remove singleSelect 
                                    isObject={false}
                                />
                            </div>
                        {/* <label>Permission</label> */}
                    </div>
                </React.Fragment>) : (title === "Add User") ? (<div className="adduserblog">
                    <div className="form-group">
                        <label>Name:</label>
                        <input type="text" className="form-control input" value={NameUser} onChange={(e) => setNameUser(e.target.value)} placeholder="Enter Name" />                        
                        {(errorName !== "" && NameUser === "") && <div className="error_field">{errorName}</div>}
                    </div>
                    <div className="checkbox_wraps">
                        <div className="form-group">
                            <label>Operator:</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" onChange={(e) => ToggleSwitchBtn(e, "Operator")} type="checkbox" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Superuser:</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" onChange={(e) => ToggleSwitchBtn(e, "Superuser")} type="checkbox" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Staff:</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" onChange={(e) => ToggleSwitchBtn(e, "Staff")} type="checkbox" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Software Vendor:</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" onChange={(e) => ToggleSwitchBtn(e, "Software_Vendor")} type="checkbox" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Marketing Vendor:</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" onChange={(e) => ToggleSwitchBtn(e, "Marketing_Vendor")} type="checkbox" />
                            </label>
                        </div>
                    </div>
                    {Staff && (<div className="form-group edit">
                        <label>Permission:</label>
                        <div className="filedsearchReltive">
                            <Multiselect
                                options={Permissionlist} // Options to display in the dropdown
                                selectedValues={selectedValueUser} // Preselected value to persist in dropdown
                                onSelect={onSelectUser} // Function will trigger on select event
                                onRemove={onRemoveUser} // Function will trigger on remove event
                                displayValue={"value"} // Property name to display in the dropdown options
                                singleSelect={false} // only single category select / for multiple category select remove singleSelect 
                                isObject={false}
                            />
                        </div>
                        {(errorPermission !== "" && selectedValueUser.length === 0) && <div className="error_field">{errorPermission}</div>}
                    </div>)}
                    <div className="form-group">
                        <label>Phone:</label>
                        <CountryMobileInput 
                            MobileNo={MobileNo}
                            setMobileNo={setMobileNo}
                        />
                        {errorMobileNo !== "" && <div className="error_field">{errorMobileNo}</div>}
                    </div>
                </div>) : (
                    <ContentEditableCommon 
                        setCurrentValue={setCurrentValue}
                        id={"addNotification"}
                />)}
            </div>
            <div className="commonpopupfooter">
                <button type="button" className="commonsbtn" onClick={title === "Add Game" || title === "Edit Game" ? AddGamesList : title === "Add User Permission" ? EditUserListf : title === "Add User" ? AddUserFnct : AddNotifications}>
                    {loader && <span className="loadersendbtns"></span>}
                    {submitbuttontitle}
                </button>
                <button type="button" className="commoncancelbtn" onClick={closeModal}>Cancel</button>
            </div>
        </React.Fragment>)}
    </div>)
} 

export default CommonPopup;