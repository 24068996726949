import React from 'react';
import Footer from './Footer';

const Layout = (props) => {

    return (<div className="page dashboardmain">
            <div className="page-wrapper">
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                        {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>)
}

export default Layout;