export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    LOGOUT_USER: "LOGOUT_USER",
    GET_ACCESS_TOKEN: "GET_ACCESS_TOKEN",
    USER_ID: "USER_ID",
    USER_LIST: "USER_LIST",
    UPDATE_USER_LIST: "UPDATE_USER_LIST",
    PROFILE_DATA: "PROFILE_DATA",
    ACTIVITY_USER_LIST_COUNT: "ACTIVITY_USER_LIST_COUNT",
    SET_STORE_VIEW_BASE_URL:"SET_STORE_VIEW_BASE_URL",
    CLEAR_STORE_VIEW_BASE_URL:"CLEAR_STORE_VIEW_BASE_URL",
    EMOJI_LIST_GET:"EMOJI_LIST_GET",
    GET_SESSION_LIST:"GET_SESSION_LIST",
    SESSION_DEAACTIVE:"SESSION_DEAACTIVE",
    GET_NOTIFICATION_LIST:"GET_NOTIFICATION_LIST",
    ADD_NOTIFICATION:"ADD_NOTIFICATION",
    EDIT_NOTIFICATION:"EDIT_NOTIFICATION",
    DELETE_NOTIFICATION:"DELETE_NOTIFICATION",
    GET_INVOICE_LIST: "GET_INVOICE_LIST",
    GET_ALLGAMES_LIST: "GET_ALLGAMES_LIST",
    ADD_GAMES: "ADD_GAMES",
    DELETE_GAMES: "DELETE_GAMES",
    EDIT_GAMES: "EDIT_GAMES",
    GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
    PERMISSION_LIST_GET: "PERMISSION_LIST_GET",
    ADD_USER_LIST: "ADD_USER_LIST"
}

export const deleteNotifications = (notification) => {
    return {
        type: ActionTypes.DELETE_NOTIFICATION,
        payload: notification,
    }
}

export const AddNewUser = (user) => {
    return {
        type: ActionTypes.ADD_USER_LIST,
        payload: user,
    }
}

export const addNotifications = (notification) => {
    return {
        type: ActionTypes.ADD_NOTIFICATION,
        payload: notification,
    }
}

export const editGamesFunct = (game) => {
    return {
        type: ActionTypes.EDIT_GAMES,
        payload: game,
    }
}

export const addGames = (game) => {
    return {
        type: ActionTypes.ADD_GAMES,
        payload: game,
    }
}

export const deleteGames = (game) => {
    return {
        type: ActionTypes.DELETE_GAMES,
        payload: game,
    }
}

export const editNotificationsFnt = (notification) => {
    return {
        type: ActionTypes.EDIT_NOTIFICATION,
        payload: notification,
    }
}

export const getAllNotifications = (notifications) => {
    return {
        type: ActionTypes.GET_NOTIFICATION_LIST,
        payload: notifications,
    }
}

export const getAllGames = (games) => {
    return {
        type: ActionTypes.GET_ALLGAMES_LIST,
        payload: games,
    }
}

export const sessionLogout = (session) => {
    return {
        type: ActionTypes.SESSION_DEAACTIVE,
        payload: session,
    }
}

export const getSessionLists = (list) => {
    return {
        type: ActionTypes.GET_SESSION_LIST,
        payload: list,
    }
}

export const getPermissionList = (permissions) => {
    return { 
        type: ActionTypes.PERMISSION_LIST_GET,
        payload: permissions,
    }
}

export const checkLoginOrNot = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const emojiListData = (emoji) => {
    return {
        type: ActionTypes.EMOJI_LIST_GET,
        payload: emoji,
    }
}

export const userIdGet = (user_id) => {
    return {
        type: ActionTypes.USER_ID,
        payload: user_id,
    }
}

export const logoutUser = () => {
    return {
        type: ActionTypes.LOGOUT_USER,
    }
}

export const getAccessToken = (Token) => {
    return {
        type: ActionTypes.GET_ACCESS_TOKEN,
        payload: Token,
    }
}

export const getProfileData = (profile) => {
    return {
        type: ActionTypes.PROFILE_DATA,
        payload: profile,
    }
}

export const getUserList = (users) => {
    return {
        type: ActionTypes.USER_LIST,
        payload: users,
    }
}

export const getInvoiceList = (invoices) => {
    return {
        type: ActionTypes.GET_INVOICE_LIST,
        payload: invoices,
    }
}

export const getCustomerList = (customers) => {
    return {
        type: ActionTypes.GET_CUSTOMER_LIST,
        payload: customers,
    }
}

export const updateUserList = (id, users) => {
    return {
        type: ActionTypes.UPDATE_USER_LIST,
        payload: {
            'users': users,
            'id': id,
        },
    }
}

export const SetStoreViewBaseURL = (payload)=>{
    return{
        type: ActionTypes.SET_STORE_VIEW_BASE_URL,
        payload: payload
    }
}

export const CleanViewBaseURL = ()=>{
    return{
        type: ActionTypes.CLEAR_STORE_VIEW_BASE_URL,
    }
}

export const activityUserCountList = (list) => {
    return {
        type: ActionTypes.ACTIVITY_USER_LIST_COUNT,
        payload: list,
    }
}


