import React from "react";
import Layout from '../Shared/Layout';
import { useSelector, useDispatch } from "react-redux";
import Chrome_Icon from "../../assets/img/browsers/chrome.png";
import Firefox_Icon from "../../assets/img/browsers/Firefox.png";
import Internet_Explorer_Icon from "../../assets/img/browsers/internet_explorer.png";
import Microsoft_Icon from "../../assets/img/browsers/microsoft.png";
import Opera_Icon from "../../assets/img/browsers/opera.png";
import Safari_Icon from "../../assets/img/browsers/safari.png";
import Logout_Icon from "../../assets/img/logout.png";
import { DEAACTIVE_TOKEN } from '../Shared/Constant';
import { sessionLogout } from '../../redux/actions/index';
import moment from "moment";
import Tooltip from "../Common/tooltip";
import toast from 'react-hot-toast';

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const Devices = () => {
    const Sessionlist = useSelector((state) => state.allReducers.sessionlist);
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const ProfileData = useSelector((state) => state.allReducers.profileDetails);
    const dispatch = useDispatch();

    const logoutSesion = (id) => {
        window.axios.get( DEAACTIVE_TOKEN + `/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setTimeout(() => {
                   dispatch(sessionLogout(result.data.data))
                   toast.success("Session deactivate Successfully...");
                }, 500);
            }
        }).catch(function (result) {
            console.log("error");
        });
    };

    return(
        <Layout>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header border-0">
                        <div className="card-title">Devices</div>
                    </div>
                    <div className='cardblogwrappr'>
                        <div className="deviceswrapper">
                            
                            {Sessionlist && Sessionlist.map((elm, index) => {
                                const BroserName = elm.user_agent.split(",")[1].replace(")", "").replace("'", "").replace("'", "");
                                const OsName = elm.user_agent.split(",")[0].replace("(", "").replace("'", "").replace("'", "");
                                const BrowserIcon = BroserName.split(" ")[1];

                                return(<li key={index}>
                                    <span className="BrowserImage">
                                        <img src={BrowserIcon === "Chrome" ? Chrome_Icon : 
                                                BrowserIcon === "Firefox" ? Firefox_Icon : 
                                                BrowserIcon === "Safari" ? Safari_Icon :
                                                BrowserIcon === "Internet" ? Internet_Explorer_Icon : 
                                                BrowserIcon === "ChromiumEdge" ? Microsoft_Icon : Opera_Icon} alt={BrowserIcon} />
                                    </span>
                                    <div className="browserDetails">
                                        <h4>{BroserName} ( {OsName} )</h4>
                                        <span>Last active {timeSince(new Date(elm.updated_at))}</span>
                                    </div> 
                                    {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("session-deactivate")) && (<Tooltip content="Deactivate Session" direction="top">
                                        <button className="Deactivatebtn" onClick={() => logoutSesion(elm?.id)}>
                                            <img src={Logout_Icon} alt="logout" />
                                        </button>
                                    </Tooltip>)}  
                                </li>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Devices;