/* eslint-disable */
import { ActionTypes } from "../../redux/actions";

const initailData = {
    is_login: "",
    accessToken: "",
    user_id: "",
    userlist: [],
    profileDetails: {},
    view_base_url: [],
    dashboardCountsList: {
        total_counts: {
            total_users: 0,
            total_superusers: 0,
            total_groups: 0,
            total_user_chats: 0,
            total_group_chats: 0
        },
        previous_counts: {
            users: 0,
            groups: 0,
            users_chat: 0,
            groups_chat: 0
        },
        percentage_comparison: {
            users: 0,
            groups: 0,
            user_chats: 0,
            group_chats: 0
        },
        count_list: [],
    },
    emojilist: {
        All: [],
        Smileys_Emotion: [],
        Animals_Nature: [],
        Food_Drink: [],
        Travel_Places: [],
        Activities: [],
        Objects: [],
        Flags: [],
    },
    sessionlist: [],
    notificationlists: [],
    gameslist: [],
    customerslists: [],
    permissionlits: []
}

export const chatReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.ADD_NOTIFICATION:
            const notifications = [...state.notificationlists];
            notifications.unshift(action.payload);
            return {
                ...state,
                notificationlists: notifications,
            };
        case ActionTypes.ADD_GAMES:
            const NewUser = [...state.gameslist];
            NewUser.unshift(action.payload);
            return {
                ...state,
                gameslist: NewUser,
            };
        case ActionTypes.EDIT_NOTIFICATION:
            const editnotifications = [...state.notificationlists];
            const editNotificationsList = editnotifications.map((elm) => {
                if (elm.id === action.payload.id) {
                    return {
                        ...elm,
                        message: action.payload.message
                    }
                }
                return elm;
            });

            return {
                ...state,
                notificationlists: editNotificationsList,
            };
        case ActionTypes.DELETE_NOTIFICATION:
            const NotificationListOld = [...state.notificationlists];
            const deletedNotification = NotificationListOld.filter((elm) => {
                if (elm.id !== action.payload.id) {
                    return elm;
                }
            });

            return {
                ...state,
                notificationlists: deletedNotification,
            };
        case ActionTypes.DELETE_GAMES:
            const gameoldlist = [...state.gameslist];
            const deleteGame = gameoldlist.filter((elm) => {
                if (elm.id !== action.payload.id) {
                    return elm;
                }
            });

            return {
                ...state,
                gameslist: deleteGame,
            };
        case ActionTypes.EDIT_GAMES:
            const editgamess = [...state.gameslist];
            const editGamesList = editgamess.map((elm) => {
                if (elm.id === action.payload.id) {
                    return {
                        ...elm,
                        game_name: action.payload.game_name,
                        company: action.payload.company
                    }
                }
                return elm;
            });

            return {
                ...state,
                gameslist: editGamesList,
            };
        case ActionTypes.GET_NOTIFICATION_LIST:
            return {
                ...state,
                notificationlists: action.payload,
            };
        case ActionTypes.GET_CUSTOMER_LIST:
            return {
                ...state,
                customerslists: action.payload,
            };
        case ActionTypes.GET_ALLGAMES_LIST:
            return {
                ...state,
                gameslist: action.payload,
            };
        case ActionTypes.PERMISSION_LIST_GET:
            const PermissionListUpdate = action.payload.filter((elm) => {
                if (elm !== "none") {
                    return elm;
                }
            });

            return {
                ...state,
                permissionlits: PermissionListUpdate,
            }
        case ActionTypes.GET_SESSION_LIST:
            return {
                ...state,
                sessionlist: action.payload,
            };
        case ActionTypes.SESSION_DEAACTIVE:
            const SessionListOld = [...state.sessionlist];
            const DeactivatedSession = SessionListOld.filter((elm) => {
                if (elm.id !== action.payload.id) {
                    return elm;
                }
            });

            return {
                ...state,
                sessionlist: DeactivatedSession,
            };
        case ActionTypes.USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case ActionTypes.GET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload,
            }
        case ActionTypes.SET_STORE_VIEW_BASE_URL:
            return {
                ...state,
                view_base_url: {
                    ...state?.view_base_url,
                    [action?.payload?.id]: action?.payload?.url
                }
            }
        case ActionTypes.CLEAR_STORE_VIEW_BASE_URL:
            return {
                ...state,
                view_base_url: []
            }
        case ActionTypes.USER_LIST:
            return {
                ...state,
                userlist: action.payload,
            }
        case ActionTypes.ADD_USER_LIST:
            const NewUserList = [...state.userlist];
            NewUserList.unshift(action.payload);
            return {
                ...state,
                userlist: NewUserList,
            }
        case ActionTypes.GET_INVOICE_LIST:
            return {
                ...state,
                invoicelist: action.payload
            }
        case ActionTypes.ACTIVITY_USER_LIST_COUNT:
            return {
                ...state,
                dashboardCountsList: {
                    total_counts: {
                        total_users: action?.payload?.total_counts?.total_users,
                        total_superusers: action?.payload?.total_counts?.total_superusers,
                        total_groups: action?.payload?.total_counts?.total_groups,
                        total_user_chats: action?.payload?.total_counts?.total_user_chats,
                        total_group_chats: action?.payload?.total_counts?.total_group_chats,
                    },
                    previous_counts: {
                        users: action?.payload?.previous_counts?.users,
                        groups: action?.payload?.previous_counts?.groups,
                        user_chats: action?.payload?.previous_counts?.user_chats,
                        group_chats: action?.payload?.previous_counts?.group_chats,
                    },
                    percentage_comparison: {
                        users: action?.payload?.percentage_comparison?.users,
                        groups: action?.payload?.percentage_comparison?.groups,
                        user_chats: action?.payload?.percentage_comparison?.user_chats,
                        group_chats: action?.payload?.percentage_comparison?.group_chats,
                    },
                    count_list: action?.payload?.count_list,
                },
            }
        case ActionTypes.PROFILE_DATA:
            return {
                ...state,
                profileDetails: action.payload,
            }
        case ActionTypes.UPDATE_USER_LIST:
            const { is_active, user_type, permission } = action.payload.users;
            const userlistUpdate = state.userlist.map((elm) => {
                if (elm.id === action.payload.id) {
                    return {
                        ...elm,
                        is_active: is_active,
                        user_type: user_type,
                        permission: permission
                    }
                } else
                    return elm
            })

            return {
                ...state,
                userlist: userlistUpdate,
            }
        case ActionTypes.EMOJI_LIST_GET:
            return {
                ...state,
                emojilist: {
                    All: action.payload,
                    Smileys_Emotion: action.payload && action.payload.filter((elm) => elm.category === 'Smileys & Emotion'),
                    Animals_Nature: action.payload && action.payload.filter((elm) => elm.category === 'Animals & Nature'),
                    Food_Drink: action.payload && action.payload.filter((elm) => elm.category === 'Food & Drink'),
                    Travel_Places: action.payload && action.payload.filter((elm) => elm.category === 'Travel & Places'),
                    Activities: action.payload && action.payload.filter((elm) => elm.category === 'Activities'),
                    Objects: action.payload && action.payload.filter((elm) => elm.category === 'Objects'),
                    Flags: action.payload && action.payload.filter((elm) => elm.category === 'Flags'),
                },
            }
        case ActionTypes.LOGOUT_USER:
            return initailData;
        default:
            return state;
    }
}