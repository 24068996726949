import React, { Component } from 'react';
import { DASHBOARD_URL, USER_URL, CHAT_URL, DEVICES, INVOICES, CUSTOMER } from '../Shared/Constant';
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (<footer className="footer footer_section">
            <div className="container-xl">
                <div className="row text-center align-items-center flex-row-reverse">
                    <div className="col-lg-auto ms-lg-auto">
                        <ul className="list-inline list-inline-dots mb-0">
                            <li className="list-inline-item"><Link className="link-secondary" to={DASHBOARD_URL} >Home</Link></li>
                            <li className="list-inline-item"><Link className="link-secondary" to={USER_URL} >User</Link></li>
                            <li className="list-inline-item"><Link className="link-secondary" to={CHAT_URL} >Chat</Link></li>
                            <li className="list-inline-item"><Link className="link-secondary" to={INVOICES} >Invoice List</Link></li>
                            <li className="list-inline-item"><Link className="link-secondary" to={CUSTOMER} >Customer List</Link></li>
                            <li className="list-inline-item"><Link className="link-secondary" to={DEVICES} >Devices</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                        <ul className="list-inline list-inline-dots mb-0">
                            <li className="list-inline-item">
                                Copyright &copy; 2022
                                All rights reserved.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>)
    }
}