/*eslint-disable */
import React, { useEffect, useState } from 'react';
import Layout from '../Shared/Layout';
import { GET_USER_LIST_API_URL, ASSIGN_ROLE_API_URL } from '../Shared/Constant';
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
import PreviewImage from "../../Components/Common/PreviewImageFiles";
import { getUserList, updateUserList } from "../../redux/actions/index";
import { useSelector, useDispatch} from 'react-redux';
import UserPermissionModal from '../Common/UserPermissionModal';
import EditIcon from "../../assets/img/permission.png";
import toast from 'react-hot-toast';
import Tooltip from '../Common/tooltip';
import CommonPopup from "../Common/CommonPopup";
import AddIcon from "../../assets/img/add-button.png";

const User = () => {
    const [ popData, setPopData ] = useState({});
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectUserLimit, setSelectUserLimit ] = useState(10);
    const dispatch = useDispatch();
    const Userlist = useSelector((state) => state.allReducers.userlist);
    const UserId = useSelector((state) => state.allReducers.user_id);
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const [ UserListSelect, setUserListSelect ] = useState("all");
    const ProfileData = useSelector((state) => state.allReducers.profileDetails);    
    const [ SearchBox, setSearchBox ] = useState("");
    const [ OpenModal, setOpenModal ] = useState(false);
    const [ popuptitle, setPopuptitle ] = useState({
        title: "",
        buttonMain: "",
    });

    useEffect(() => {
        userFilter("all", 1, selectUserLimit);
    }, [selectUserLimit, UserListSelect, SearchBox]);

    const currentFunction = (pageNumber) => {
        return setCurrentPage(pageNumber, userFilter("all", pageNumber, selectUserLimit));      
    };

    const userFilter = (type, currentPages, limit) => {       
        window.axios.get(GET_USER_LIST_API_URL+`${UserListSelect}/${limit}/${currentPages}/${SearchBox === "" ? "none" : SearchBox}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(getUserList(result?.data?.data))
                setPaginationLength(result.data.pagination);
            }
        }).catch(function (result) {
        });
    }

    const userInfo = (id, name, phone_code, phone, role, action) => {
        setPopData({ 
            "id": id, 
            "name": name, 
            "phone": phone_code + phone, 
            "action" : action ,
            "role" : role
        })
    }

    const userUpdate = () => {
        const superuserJson = JSON.stringify({ user_key: popData.role, user_value: +(!popData.action), user_id: popData.id });
        window.axios.post(ASSIGN_ROLE_API_URL, superuserJson, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data.data) {
                dispatch(updateUserList(popData.id, result.data.data));
                setTimeout(() => {
                    toast.success("Role Updated Successfully...");
                }, 500);
                document.getElementById("closepopup").click();
            }
        }).catch(function (result) {
            toast.error("Role Updated not Successfully...");
        })
    };

    const UserListFilter = (type) => {
        setUserListSelect(type);
        setCurrentPage(1);
    }

    const SelectLimit = (limits) => {
        setSelectUserLimit(limits);
        setCurrentPage(1);
    }

    const editUser = (elm) => {
        setOpenModal(!OpenModal);
        setPopuptitle({...popuptitle, title : "Add User Permission", buttonMain: "Ok", UserData: elm});
    }

    const CreateUser = () => {
        setOpenModal(!OpenModal);
        setPopuptitle({...popuptitle, title : "Add User", buttonMain: "Ok"});
    };
    
    return (
        <Layout>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header border-0">
                        <div className="card-title">User Details list :</div>
                        <div className='cradRightsection'>
                            <input type="text" className="form-filter ms-3" value={SearchBox} onChange={(e) => setSearchBox(e.target.value)} placeholder='Search Users'/>
                            <select defaultValue="all" className="col-6-form-select ms-3" onChange={(e) => UserListFilter(e.target.value)} aria-label="Default select example" >
                                <option value="all" >All User</option>
                                <option value="superuser" >Superuser</option>
                                <option value="staff" >Staff Users</option>
                                <option value="operator" >Operator User</option>
                                <option value="software_vendor">Software Vendor</option>
                                <option value="marketing_vendor">Marketing Vendor</option>
                            </select>
                            <select defaultValue="Select Limit" className="col-6-form-select ms-3" onChange={(e) => SelectLimit(e.target.value)} aria-label="Default select example">
                                <option value="Select Limit" >Select Limit</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="80">90</option>
                                <option value="100">100</option>
                            </select>
                            {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("add-user")) && (<button className='addnotifcation' style={{ marginLeft: "10px" }} onClick={() => CreateUser()}>
                            <img src={AddIcon} alt="add notification" />
                            Add User
                        </button>)}
                        </div>
                    </div>
                    <div className="card-table table-responsive">
                        <table className="table table-vcenter">
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>User Avatar</th>
                                    <th>User Phone</th>
                                    <th>User Active</th>
                                    {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<th>User Operator</th>)}
                                    {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<th>User Software Vendor</th>)}
                                    {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<th>User Marketing Vendor</th>)}
                                    {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<>
                                        <th>User Staff</th>
                                        <th>User Superuser</th>
                                    </>)}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Userlist && Userlist.length > 0 && Userlist.map((elm, index) => {
                                    return(<tr key={index} style={{ display: elm?.id === UserId && "none"}}>
                                        <td>{elm?.name === null ? `+${elm?.phone_code + elm?.phone}` : elm?.name}</td>
                                        <td>
                                            <PreviewImage type="image" url={elm?.view_thumbnail_url} title={elm?.name} />
                                        </td>
                                        <td>
                                            +{elm?.phone_code + elm?.phone}
                                        </td>
                                        <td>
                                            <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.is_active ? "<span class='badge bg-green'>Yes</span>" : "<span class='badge bg-red'>No</span>" }}>
                                            </div>
                                        </td>
                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<td>
                                            <label className="form-check form-switch">
                                                <input className="form-check-input" data-bs-toggle="modal" data-bs-target="#modal-danger" onChange={e => {}} type="checkbox" checked={elm?.user_type.includes("operator")} onClick={() => userInfo(elm?.id, elm?.name, elm?.phone_code, elm?.phone, "operator",elm?.user_type.includes("operator"))} />
                                                <span className="form-check-label" >
                                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.user_type.includes("operator") ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>" }}></div>
                                                </span>
                                            </label>
                                        </td>)}
                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<td>
                                            <label className="form-check form-switch">
                                                <input className="form-check-input" data-bs-toggle="modal" data-bs-target="#modal-danger" onChange={e => {}} type="checkbox" checked={elm?.user_type.includes("software_vendor")} onClick={() => userInfo(elm?.id, elm?.name, elm?.phone_code, elm?.phone, "software_vendor",elm?.user_type.includes("software_vendor"))} />
                                                <span className="form-check-label" >
                                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.user_type.includes("software_vendor") ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>" }}></div>
                                                </span>
                                            </label>
                                        </td>)}
                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<td>
                                            <label className="form-check form-switch">
                                                <input className="form-check-input" data-bs-toggle="modal" data-bs-target="#modal-danger" onChange={e => {}} type="checkbox" checked={elm?.user_type.includes("marketing_vendor")} onClick={() => userInfo(elm?.id, elm?.name, elm?.phone_code, elm?.phone, "marketing_vendor",elm?.user_type.includes("marketing_vendor"))} />
                                                <span className="form-check-label" >
                                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.user_type.includes("marketing_vendor") ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>" }}></div>
                                                </span>
                                            </label>
                                        </td>)}
                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<>
                                            <td>
                                                <label className="form-check form-switch">
                                                    <input className="form-check-input" data-bs-toggle="modal" data-bs-target="#modal-danger" onChange={e => {}} type="checkbox" checked={elm?.user_type.includes("staff")} onClick={() => userInfo(elm?.id, elm?.name, elm?.phone_code, elm?.phone, "staff", elm?.user_type.includes("staff"))} />
                                                    <span className="form-check-label" >
                                                        <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.user_type.includes("staff") ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>" }}></div>
                                                    </span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="form-check form-switch">
                                                    <input className="form-check-input" data-bs-toggle="modal" data-bs-target="#modal-danger" onChange={e => {}} type="checkbox" checked={elm?.user_type.includes("superuser")} onClick={() => userInfo(elm?.id, elm?.name, elm?.phone_code, elm?.phone, "superuser", elm?.user_type.includes("superuser"))} />
                                                    <span className="form-check-label" >
                                                        <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.user_type.includes("superuser") ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>" }}></div>
                                                    </span>
                                                </label>
                                            </td>
                                        </>)}
                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-user")) && (<td>
                                            {elm?.user_type?.includes("staff") ? (<Tooltip content="User Permission" direction="top">
                                                <button type='button' className="tablesbtn" onClick={() => editUser(elm)} >
                                                    <img src={EditIcon} className='permissionIcon' alt="edit user" />
                                                </button>
                                            </Tooltip>) : ("-")}
                                        </td>)}
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <UserPermissionModal popData={popData} userUpdate={userUpdate} />
            {/* popup here */}
            {OpenModal && (<CommonPopup 
                setOpenModal={setOpenModal}
                title={popuptitle.title} 
                submitbuttontitle={popuptitle.buttonMain}
                setPaginationLength={setPaginationLength}
                popuptitle={popuptitle}
                setPopuptitle={setPopuptitle}
            />)}
            {OpenModal && (<div className='modalBckground' onClick={() => setOpenModal(false)}></div>)}

            <div className='paginationCustom'>
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={paginationLength?.record_limit}
                    onPageChange={(pageNumber) => currentFunction(pageNumber)}
                    totalItems={paginationLength?.total_records}
                    pageNeighbours={2}
                />
            </div>
                                        
            <div className="col-12">
                <div className="card card-md">
                    <div className="card-stamp card-stamp-lg">
                        <div className="card-stamp-icon bg-primary">

                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7" /><line x1="10" y1="10" x2="10.01" y2="10" /><line x1="14" y1="10" x2="14.01" y2="10" /><path d="M10 14a3.5 3.5 0 0 0 4 0" /></svg>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default User;