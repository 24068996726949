import React, { useState } from "react";
import Tooltip from "../Common/tooltip";
import { UPLOAD_FILE_URL } from "../Shared/Constant";
import Document from "../../assets/img/uploadbutton/Document.svg";
import Images from "../../assets/img/uploadbutton/Image.svg";
import Video from "../../assets/img/uploadbutton/Video.svg";
import Audio from "../../assets/img/uploadbutton/Audio.svg";
import spinner_transferent from "../../assets/img/spinner_transferent.svg";

const UploadFilesBox = (props) => {
    const { setUploadFile, accessToken, setfileUploadBox } = props;
    const [loader, setLoader] = useState({
        loader_1: false,
        loader_2: false,
        loader_3: false,
        loader_4: false,
    });

    // files upload function
    const filesUpload = (e) => {
        setLoader({loader_1: true});
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        window.axios.post( UPLOAD_FILE_URL, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setUploadFile(result?.data);
                setTimeout(()=> setLoader({loader_1: false}), 2000);
                setfileUploadBox(false);
            }
        }).catch(function (result) {
            setUploadFile("");
        });
    };

    // images upload function
    const ImageUpload = (e) => {
        setLoader({loader_2: true});
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        window.axios.post( UPLOAD_FILE_URL, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setUploadFile(result?.data);
                setTimeout(()=> setLoader({loader_2: false}), 2000);
                setfileUploadBox(false);
            }
        }).catch(function (result) {
            setUploadFile("");
        });
    };

    // video upload function
    const VideoUpload = (e) => {
        setLoader({loader_3: true});
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        window.axios.post( UPLOAD_FILE_URL, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setUploadFile(result?.data);
                setTimeout(()=> setLoader({loader_3: false}), 2000);
                setfileUploadBox(false);
            }
        }).catch(function (result) {
            setUploadFile("");
        });
    };

    // Audio upload function
    const AudioUpload = (e) => {
        setLoader({loader_4: true});
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        window.axios.post( UPLOAD_FILE_URL, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setUploadFile(result?.data);
                setTimeout(()=> setLoader({loader_4: false}), 2000);
                setfileUploadBox(false);
            }
        }).catch(function (result) {
            setUploadFile("");
        });
    };

    return(
        <div className="uploadfilewrapper">
            <ul>
                <li>
                    <Tooltip content="Upload Document" direction="top">
                        <label className="uploadfilesset">
                            {loader.loader_1 === true && (<div className="filloaderwrapper">
                                <img src={spinner_transferent} className="fileuplodloader" alt="loader" />
                            </div>)}
                            <img src={Document} alt="file upload"  />
                            <input
                                type="file"
                                onChange={ filesUpload }
                                id="multipleimage"
                                accept=".txt, .pdf, .xls, .xlsx, .ppt, .doc, .docx"
                                multiple
                            />
                        </label>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip content="Image" direction="top">
                        <label className="uploadfilesset">
                            {loader.loader_2 === true && (<div className="filloaderwrapper">
                                <img src={spinner_transferent} className="fileuplodloader"  alt="loader" />
                            </div>)}
                            <img src={Images} alt="file upload" />
                            <input
                                type="file"
                                onChange={ImageUpload}
                                id="multipleimage"
                                accept=".png, .jpg, .jpeg"
                            />
                        </label>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip content="Video" direction="top">
                        <label className="uploadfilesset">
                            {loader.loader_3 === true && (<div className="filloaderwrapper">
                                <img src={spinner_transferent} className="fileuplodloader"  alt="loader" />
                            </div>)}
                            <img src={Video} alt="file upload"  />
                            <input
                                type="file"
                                onChange={ VideoUpload }
                                id="multipleimage"
                                accept=".mp4, .mov, .3gp"
                                multiple
                            />
                        </label>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip content="Audio" direction="top">
                        <label className="uploadfilesset">
                            {loader.loader_4 === true && (<div className="filloaderwrapper">
                                <img src={spinner_transferent} className="fileuplodloader"  alt="loader" />
                            </div>)}
                            <img src={Audio} alt="file upload"  />
                            <input
                                type="file"
                                onChange={ AudioUpload }
                                id="multipleimage"
                                accept=".mp3, .m4a, .aac, .wav"
                                multiple
                            />
                        </label>
                    </Tooltip>
                </li>
            </ul>
        </div>
    )
}

export default UploadFilesBox;