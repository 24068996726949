/*eslint-disable */
import React, { useState } from 'react';
import './Login.css';
import toast, { Toaster } from 'react-hot-toast';
import OTPInput, { ResendOTP } from "otp-input-react";
import { LOGIN_API_URL, SEND_OTP_API_URL, LOGO_URL, DASHBOARD_URL } from '../Shared/Constant';
import { useNavigate } from "react-router-dom";
import { getAccessToken, checkLoginOrNot } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import CountryMobileInput from '../Common/CountrySelectInput/CountryMobileInput';

const Login = () => {
    let navigate = useNavigate();
    const [OTP, setOTP] = useState("");
    const dispatch = useDispatch();

    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "+1",
        number: "",
    });
    
    function handleSubmitOTP(e) {
        e.preventDefault();
        handleSendOTP();
    }
    
    function handleSendOTP() {    
        if(MobileNo?.number !== "" && MobileNo?.countrycode !== "") {
            const jsonData1 = JSON.stringify({ phone: MobileNo.number, phone_code: MobileNo.countrycode });
            window.axios.post(SEND_OTP_API_URL, jsonData1, {
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
            }).then(function (result) {
                handleHideShow(result);
            })
            .catch(function (result) {
                toast.error(result?.response?.data?.errors?.error);
            });
        } else {
            if(MobileNo.number === "") {
                toast.error("Please enter mobile no.");
            } 
            if(MobileNo.countrycode === "") {
                toast.error("Please select country");
            }
        }
    };

    function handleHideShow(result){
        toast.success("OTP Sent Successfully...");
        document.getElementById("phone-form").style.display="none";
        document.getElementById("otp-form").style.display="block";
    };


    const backBtn = () => {
        document.getElementById("phone-form").style.display="block";
        document.getElementById("otp-form").style.display="none";
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const jsonData = JSON.stringify({ phone: MobileNo.number, phone_code: MobileNo.countrycode, otp: OTP, device_type: "web", registration_id: "web" });
        window.axios.post(LOGIN_API_URL, jsonData, {
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function (result) {
            if(result.data.data.user.user_type.includes("superuser") === true || result.data.data.user.user_type.includes("staff")){
                dispatch(getAccessToken(result.data.data.access_token));
                dispatch(checkLoginOrNot("yes"));
                setTimeout(()=>navigate(DASHBOARD_URL), 100);
            }else{
                dispatch(getAccessToken(""));
                dispatch(checkLoginOrNot("no"));
                toast.error("Please login with admin user...");
            }
        })
        .catch(function (result) {
            toast.error("Please login with admin user");
        });
    }

    return (
        <div className="page page-center loginform_wrapper">
            <div 
                className="container-tigh py-4" 
                style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems:"center"
                }}
            >
                <form className="card card-md login-form" id="phone-form" onSubmit={handleSubmitOTP} autoComplete="off">
                    <div className="card card-md login-form" >
                        <div className="card-body">
                            <div className="logowraper">
                                <img src={LOGO_URL} height="100" alt="" />
                            </div>
                            <h2 className="card-title text-center mb-4">Login to your account</h2>
                            <div className='loginformstart'>
                                <div className="mb-3">
                                    <label className="form-label">Enter your phone number:</label>
                                    <CountryMobileInput 
                                        MobileNo={MobileNo}
                                        setMobileNo={setMobileNo}
                                    />
                                </div>
                                <div className="mb-2 rem-check">
                                    <label className="form-check">
                                        <input type="checkbox" className="form-check-input" />
                                        <span className="form-check-label">Remember me on this device</span>
                                    </label>
                                </div>
                                <div className="form-footer">
                                    <button type="submit" className="btn btn-primary w-100">Send OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form className="card card-md login-form" id="otp-form" onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">Login to your account</h2>
                        <div className='loginformstart loginformstartotp'>
                            <div className="mb-3 otp_wrapper">
                                <label className="form-label">Enter your OTP:</label>
                                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                <ResendOTP onResendClick={() => handleSendOTP()} />
                            </div>
                            <div className="form-footer">
                                <button type="submit" className="btn btn-primary w-100">Sign in</button>
                                <button type="button" className="btn btn-link w-100" onClick={() => backBtn()}>Back</button>
                            </div>
                        </div>
                    </div>
                </form>

                <Toaster />
            </div>
        </div>
    );
}

export default Login;