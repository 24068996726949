import React from "react";
import dummyImage from "../../assets/img/dummyimage.png";
import DefaultProduct from "../../assets/img/DefaultProduct.jpg";

const PreviewImageFiles = (props) => {
    const { type, url, title} = props;
    return(<div className="imagepreviewBox">
        <img 
            src={url} alt={title} 
            onError={(e)=> {e.target.src = (type === "imageupload" ?  DefaultProduct : dummyImage)}}
        />
    </div>)
}

export default PreviewImageFiles;