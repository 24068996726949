/* eslint-disable */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import './assets/css/style.css';
import Login from './Components/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import User from './Components/User/User';
import Chat from './Components/Chat/Chat';
import Devices from './Components/Devices/Devices';
import Invoice from './Components/Invoice/invoice';
import Games from './Components/Games/Games';
import Customer from './Components/Customer/Customer';
import axios from 'axios';
import { GET_USER_API_URL, ACTIVE_SESSION_LIST, GET_PERMISTIONS } from './Components/Shared/Constant';
import { useSelector, useDispatch} from 'react-redux';
import { userIdGet, getProfileData, getPermissionList, CleanViewBaseURL, emojiListData, getSessionLists, getAccessToken, checkLoginOrNot, logoutUser } from './redux/actions/index';
import { Navigate } from "react-router-dom";
import Header from './Components/Shared/Header';
import { Toaster } from 'react-hot-toast';
import emoji from "../src/Components/Common/emojiCustom/emoji.json";
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const App = () => {
  const ProfileData = useSelector((state) => state.allReducers.profileDetails);
  const accessToken = useSelector((state) => state.allReducers.accessToken);
  const IS_Login_Check = useSelector((state) => state.allReducers.is_login);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(accessToken !== "") {
      getPermistions();
    }
  },[ accessToken ])

  // get contact list
  const getPermistions = () => {
      window.axios.get(`${GET_PERMISTIONS}`, {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + accessToken,
          }
      }).then(function (result) {
          dispatch(getPermissionList(result.data.data));
      }).catch(function (result) {
          dispatch(getPermissionList([]));
          if(result.response.data.detail === "Token expired.") {
            setTimeout(() => {
              localStorage.removeItem("access_token");
              dispatch(getAccessToken(""));
              dispatch(logoutUser());
              dispatch(checkLoginOrNot(""));
            }, 200);
          } else if (result.response.data.detail === "Invalid token.") {
            setTimeout(() => {
              localStorage.removeItem("access_token");
              dispatch(getAccessToken(""));
              dispatch(logoutUser());
              dispatch(checkLoginOrNot(""));
            }, 200);
          }
      });
  };

  const GetProfileDetails = () => {
    window.axios.get(GET_USER_API_URL, {
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ accessToken
        }
    }).then(function (result) {
        dispatch(getProfileData(result?.data?.data))
        dispatch(userIdGet(result?.data?.data?.id));
    })
    .catch(function (result) {
      if(result.response.data.detail === "Token expired.") {
          setTimeout(() => {
            localStorage.removeItem("access_token");
            dispatch(getAccessToken(""));
            dispatch(logoutUser());
            dispatch(checkLoginOrNot(""));
          }, 200);
      } else if (result.response.data.detail === "Invalid token.") {
        setTimeout(() => {
          localStorage.removeItem("access_token");
          dispatch(getAccessToken(""));
          dispatch(logoutUser());
          dispatch(checkLoginOrNot(""));
        }, 200);
      }
    });
  };

  const GetSessionList = () => {
    window.axios.get( ACTIVE_SESSION_LIST, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        }
    }).then(function (result) {
        if (result.data) {
            setTimeout(() => {
               dispatch(getSessionLists(result.data.data));
            }, 500);
        }
    }).catch(function (result) {
        if(result.response.data.detail === "Token expired.") {
          setTimeout(() => {
            localStorage.removeItem("access_token");
            dispatch(getAccessToken(""));
            dispatch(logoutUser());
            dispatch(checkLoginOrNot(""));
          }, 200);
        }
    });
  }
  
  useEffect(() => {
    if(accessToken) {
      GetProfileDetails();
      GetSessionList();
    }
  }, [accessToken]);

  useEffect(() => {
      dispatch(CleanViewBaseURL());
  }, []);

  useEffect(() => {
    if(emoji) {
        dispatch(emojiListData(emoji));
    }
  }, [ accessToken ])

  return (
    <div className="wrapper">
      <BrowserRouter>
        {(accessToken && IS_Login_Check) && <Header ProfileData={ProfileData} />}
        <Routes>
          <Route path="*" element={(!accessToken && !IS_Login_Check) ? <Navigate replace to={"/login"}/> : <Navigate replace to={"/dashboard"} />} />
          <Route path="/" element={(!accessToken && !IS_Login_Check) ? <Login /> : <Navigate replace to={"/dashboard"} />} />
          <Route path="/Login" element={(!accessToken && !IS_Login_Check) ? <Login /> : <Navigate replace to={"/dashboard"} />} />
          <Route path="/dashboard" element={(accessToken && IS_Login_Check) ? <Dashboard /> : <Navigate replace to={"/Login"} />} />
          <Route path="/user" element={(accessToken && IS_Login_Check) ? <User /> : <Navigate replace to={"/Login"} />} />
          <Route path="/chat" element={(accessToken && IS_Login_Check) ? <Chat /> : <Navigate replace to={"/Login"} />} />
          <Route path="/devices" element={(accessToken && IS_Login_Check) ? <Devices /> : <Navigate replace to={"/Login"} />} />
          <Route path="/invoice" element={(accessToken && IS_Login_Check) ? <Invoice /> : <Navigate replace to={"/Login"} />} />
          <Route path="/games" element={(accessToken && IS_Login_Check) ? <Games /> : <Navigate replace to={"/Login"} />} />
          <Route path="/customer" element={(accessToken && IS_Login_Check) ? <Customer /> : <Navigate replace to={"/Login"} />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </div>
    );
}

export default App;
