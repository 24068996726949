/* eslint-disable */
import React from 'react';
import PermsissionIcon from "../../assets/img/permission.png";

const UserPermissionModal = (props) => {
    const { popData, userUpdate } = props;

    return(
        <div className="modal popupConfirmationwrapper modal-blur fade" id="modal-danger" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body text-center py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className={popData.action !== true ? "icon mb-2 text-primary icon-lg" : "icon mb-2 text-danger icon-lg"} width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
                        <h3>Are you sure?</h3>
                        <div className="text-muted">Do you really want to {popData.action ? "remove" : "assign"} {popData.role} to {popData.name}?</div>
                        <div className="text-muted">phone : +{popData.phone}<br></br></div>

                        {(!popData.action && popData.role === "staff") && (<div className='notpermission'>
                            <div className="text-muted">If you are an active staff user, then you need to add permissions. Click on the Permissions <img src={PermsissionIcon} alt=''/> button.
                        </div></div>)}
                    </div>
                    <div className="modal-footer">
                        <div className="w-100">
                            <div className="row">
                                <div className="col" style={{ height: "40px", display: "flex", columnGap: "15px" }}>
                                    <a id="closepopup"  className="btn w-100" data-bs-dismiss="modal">Cancel</a>
                                    <a  className={popData.action !== true ? "btn btn-primary w-100": "btn btn-danger w-100"} onClick={userUpdate}>
                                        {popData.action ? "Remove " : "Assign "} 
                                        {popData.role === "operator" ? "operator" : 
                                        popData.role === "software_vendor" ? "Software Vendor" :
                                        popData.role === "marketing_vendor" ? "Marketing Vendor" :
                                        popData.role === "staff" ? "Staff" : "Superuser"}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPermissionModal;