/* eslint-disable */
import React, { useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import { getAllGames } from "../../redux/actions";
import AddIcon from "../../assets/img/add-button.png";
import { useSelector, useDispatch } from 'react-redux';
import { GET_GAMES_LIST } from "../Shared/Constant";
import CommonPopup from "../Common/CommonPopup";
import Tooltip from "../Common/tooltip";
import EditIcon from "../../assets/img/edit.png";
import DeleteIcon from "../../assets/img/delete.png";
import moment from 'moment';
import { Pagination } from "react-pagination-bar";

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const Games = () => {
    const [ gamesSearch, setGamesSearch ] = useState("none");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ selectGamesLimit, setSelectGamesLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const GamesList = useSelector((state) => state.allReducers.gameslist);
    const ProfileData = useSelector((state) => state.allReducers.profileDetails);
    const [ OpenModal, setOpenModal ] = useState(false);
    const [ popuptitle, setPopuptitle ] = useState({
        title: "",
        buttonMain: "",
    });
    const [ DeleteId, setDeleteId ] = useState("");
    const [ editGamesState, seteditGamesState ] = useState({
        id: "",
        game: "",
    });
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if(gamesSearch !== "") {
            GetGamesLists();
        }
    }, [ gamesSearch, currentPage, selectGamesLimit ]);


    // get games list
    const GetGamesLists = () => {
        window.axios.get(GET_GAMES_LIST+`${selectGamesLimit}/${currentPage}/${gamesSearch}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(getAllGames(result.data.data));
                setPaginationLength(result.data.pagination);
            }
        }).catch(function (result) {
            console.log("notifaction error........")
        });
    };

    // add game
    const CreateGame = () => {
        setOpenModal(!OpenModal);
        setPopuptitle({...popuptitle, title : "Add Game", buttonMain: "Add"});
        seteditGamesState({
            ...editGamesState,
            id: "",
            game: "",
            company:  "",
        });
    };

    // delete game
    const deleteGames = (elm) => {
        setOpenModal(!OpenModal);
        setDeleteId(elm.id);
        setPopuptitle({...popuptitle, title : "Delete Game", buttonMain: "Delete"});
    };

    // edit games 
    const editGames = (elm) => {
        seteditGamesState({
            ...editGamesState,
            id: elm.id,
            game: elm.game_name,
            company:  elm.company,
        });
        setOpenModal(true);
        setPopuptitle({...popuptitle, title : "Edit Game", buttonMain: "Save"});
        setTimeout(() => {
            // cursor focus contenteditable div
            const el = document.getElementById("gamename");
            el.focus();
            el.select();
        }, 500);
    }

    return(<Layout>
        <div className="col-lg-12">
            <div className="card">
                <div className="card-header border-0">
                    <div className="card-title">Games :</div>
                    <div className="notifctionbtnsearch cradRightsection">
                        <select defaultValue="Select Limit" className="col-6-form-select ms-3" onChange={(e) => setSelectGamesLimit(e.target.value)} aria-label="Default select example" >
                            <option value="Select Limit" >Select Limit</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="80">90</option>
                            <option value="100">100</option>
                        </select>
                        <input type="text" className="searchBoxsml" onChange={(e) => setGamesSearch(e.target.value !== "" ? e.target.value : "none")} placeholder='Search Games..'/>
                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("add-game")) && (<button className='addnotifcation' onClick={() => CreateGame()}>
                            <img src={AddIcon} alt="add notification" />
                            Add Game
                        </button>)}
                    </div>
                </div>
                <div className="card-table table-responsive">
                        <table className="table table-vcenter">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Game Name</th>
                                    <th>Company Name</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {GamesList && GamesList.slice(0, selectGamesLimit).map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>{((paginationLength.current_page !== undefined && paginationLength.current_page - 1) * 10)+ index +1}</td>
                                        <td>{elm?.game_name}</td>
                                        <td>{elm?.company}</td>
                                        <td>{timeSince(new Date(elm?.created_at))}</td>
                                        {(ProfileData?.user_type?.includes("superuser") || (ProfileData?.permission?.includes("update-game") || ProfileData?.permission?.includes("delete-game"))) && (<td>
                                            {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-game")) && (<Tooltip content="Edit Notification" direction="top">
                                                <button type='button' className="tablesbtn" onClick={() => editGames(elm)} >
                                                    <img src={EditIcon} alt="edit notification" />
                                                </button>
                                            </Tooltip>)}
                                            {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("delete-game")) && (<Tooltip content="Delete Notification" direction="top">
                                                <button type='button' className="tablesbtn" onClick={() => deleteGames(elm)} >
                                                    <img src={DeleteIcon} alt="delete notification" />
                                                </button>
                                            </Tooltip>)}
                                        </td>)}
                                    </tr>)
                                })}
                                {GamesList && GamesList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={4}>No Games found</td></tr>}
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>

        {paginationLength.total_records > 10 && (<div className='paginationCustom'>
            <Pagination
                currentPage={currentPage}
                itemsPerPage={paginationLength?.record_limit}
                onPageChange={(pageNumber) => currentFunction(pageNumber)}
                totalItems={paginationLength && paginationLength.total_records}
                pageNeighbours={2}
            />
        </div>)}

        {/* popup here */}
        {OpenModal && (<CommonPopup 
            setOpenModal={setOpenModal}
            title={popuptitle.title} 
            submitbuttontitle={popuptitle.buttonMain}
            setPaginationLength={setPaginationLength}
            DeleteId={DeleteId}
            editGamesState={editGamesState}
            seteditGamesState={seteditGamesState}
        />)}
        {OpenModal && (<div className='modalBckground' onClick={() => setOpenModal(false)}></div>)}
    </Layout>)
};

export default Games;