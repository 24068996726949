/* eslint-disable */
import React, { useEffect, useState } from "react";
import EmojiPicker from "./emojiCustom/EmojiPicker";
import EmojiImage from '../../assets/img/emoji_icon.svg';

const ContentEditableCommon = (props) => {
    const { id, setCurrentValue } = props;
    const [ picker, setpicker ] = useState(false);
    const isOpenFireFoxBrowser = navigator.userAgent.indexOf("Firefox") != -1;

    // shift enter disable
    useEffect(() => {
        document.getElementById(id).addEventListener('keypress', (evt) => {
            if (evt.which === 13) {
                evt.preventDefault();
            }
        });
    }, []);

    // get current value
    const getCurrentValue = (e) => {
        setCurrentValue(e.target.innerText);
    };

    useEffect(() => {
        setTimeout(() => {
            const currentmsghtml = document.getElementById(id)?.innerText;
            setCurrentValue(currentmsghtml);
        }, 200);
    }, [ picker ]);

    const onEnterAddBreakdown = (e) => {
        const keyCode = e.which || e.keyCode;
        if(keyCode === 13 && e.shiftKey || keyCode === 13 && e.altKey) {
            if(isOpenFireFoxBrowser !== true) {
                e.preventDefault();
                document.execCommand('insertLineBreak');
            } else {
                e.preventDefault();
                document.execCommand("insertHtml", false, " ");
                document.execCommand('insertParagraph',false, ''); 
            } 
        }
    };

    return(<div className="contentEdtblewrapper">
        <div 
            contentEditable={true}
            className="messagField" 
            id={id}
            onInput={(e) => getCurrentValue(e)}
            placeholder="Type here…"
            onKeyDown={(e) => onEnterAddBreakdown(e)}
            data-text="Type here…"
        ></div>
        <button type="button" className="emojiselectcmn" onClick={() => setpicker(!picker)}>
            <img src={EmojiImage} alt="emoji select" />
        </button>
        {picker === true && (<EmojiPicker id={id} setpicker={setpicker} />)}
    </div>)
}

export default ContentEditableCommon;