/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Layout from '../Shared/Layout';
import { GET_USER_LIST_API_URL, ADD_CHAT, GET_NOTIFICATION_LIST, SEND_SYSTEM_NOTIFICATION } from '../Shared/Constant';
import { useSelector, useDispatch } from 'react-redux';
import PreviewImage from "../../Components/Common/PreviewImageFiles";
import SearchIcon from '../../assets/img/searchBox.png';
import CloseIcon from '../../assets/img/cancel_icon.svg';
import toast from 'react-hot-toast';
import EmojiPicker from '../Common/emojiCustom/EmojiPicker';
import EmojiImage from '../../assets/img/emoji_icon.svg';
import FileAttached from '../../assets/img/uploadfilechat.svg';
import UploadFilesBox from '../Common/UploadFilesBox';
import { getAllNotifications } from "../../redux/actions/index";
import moment from 'moment';
import EditIcon from "../../assets/img/edit.png";
import DeleteIcon from "../../assets/img/delete.png";
import AddIcon from "../../assets/img/add-button.png";
import NotificationIcon from "../../assets/img/notificationlist.png";
import SendIcon from "../../assets/img/send.png";
import Tooltip from '../Common/tooltip';
import CommonPopup from '../Common/CommonPopup';
import { Pagination } from "react-pagination-bar";

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const Chat = () =>  {
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const notifications = useSelector((state) => state.allReducers.notificationlists);
    const ProfileData = useSelector((state) => state.allReducers.profileDetails);
    const isOpenFireFoxBrowser = navigator.userAgent.indexOf("Firefox") != -1;
    const [ SearchValue, setSearchValue ] = useState("");
    const [ selectTypeUser, setselectTypeUser ] = useState("");
    const [ searchList, setSearchList ] = useState([]);
    const [ selectUserList, setSelectUserList ] = useState([]);
    const [ openSearchBox, setopenSearchBox ] = useState(false);
    const [ error, setError ] = useState("");
    const [ picker, setpicker ] = useState(false);
    const [ uploadFile, setUploadFile ] = useState("");
    const [ fileUploadBox, setfileUploadBox ] = useState(false);
    const [ loader, setLoder ] = useState(false);
    const [ notificationSearch, setNotificationSearch ] = useState("none");
    const [ editNotification, seteditNotification ] = useState({
        id: "",
        message: "",
    });
    const dispatch = useDispatch();
    const [ selectTab, setSelectTab ] = useState((ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("add-chat")) ? "User_announcement" : "Notification_management");
    const [ OpenModal, setOpenModal ] = useState(false);
    const [ NotificationSelect, setNotificationSelect ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ popuptitle, setPopuptitle ] = useState({
        title: "",
        buttonMain: "",
    });
    const [ DeleteId, setDeleteId ] = useState("");
    const [ SearchNotifications, setSearchNotifications ] = useState("");
    const [ openNotificationSearchBox, setNotificationSearchBox ] = useState(false);
    const [ searchNotifctionList, setSearchNotifctionList ] = useState([]);
    const [ selectUserLimit, setSelectUserLimit ] = useState(10);

    useEffect(() => {
        SearchNotificationList();
    }, [SearchNotifications]);

    const SearchNotificationList = () => {
        if(SearchNotifications) {
            window.axios.get(GET_NOTIFICATION_LIST+`${SearchNotifications}`+`/5000/${currentPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(function (result) {
                if (result.data) {
                    setSearchNotifctionList(result.data.data);
                    setNotificationSearchBox(true);
                    setError("");
                }
            }).catch(function (result) {
                setError(result.response.data.error);
                setNotificationSearchBox(false);
            });
        }
    };
    
    useEffect(() => {
        if(SearchNotifications === "") {
            setSearchNotifctionList([]);
            setError("");
        }
    }, [SearchNotifications]);

    useEffect(() => {
        SearchUserList();
    }, [SearchValue]);

    const SearchUserList = () => {
        if(SearchValue) {
            window.axios.get(GET_USER_LIST_API_URL+`all/5000/1`+ `/${SearchValue}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(function (result) {
                if (result.data) {
                    setSearchList(result.data.data);
                    setopenSearchBox(true);
                    setError("");
                }
            }).catch(function (result) {
                setError(result.response.data.error);
                setopenSearchBox(false);
            });
        }
    };
    
    useEffect(() => {
        if(SearchValue === "") {
            setSearchList([]);
            setError("");
        }
    }, [SearchValue]);

    const GetNotificaionLists = () => {
        window.axios.get(GET_NOTIFICATION_LIST+`${notificationSearch}`+`/${selectUserLimit}/${currentPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(getAllNotifications(result.data.data));
                setPaginationLength(result.data.pagination);
            }
        }).catch(function (result) {
            console.log("notifaction error........")
        });
    };

    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if(notificationSearch !== "") {
            GetNotificaionLists();
        }
    }, [ notificationSearch, currentPage, selectUserLimit ]);

    // selected user list show name avatar in box
    let selectedUserArray = [...selectUserList];
    const SelectSearchUser = (selectdata) => {
        selectedUserArray.push(selectdata);
        let uniqueObjArray = [...new Map(selectedUserArray.map((item) => [item["id"], item])).values(),];
        setSelectUserList(uniqueObjArray);
        setopenSearchBox(false);
        setSearchList([]);
        setSearchValue("");
    };

    // removed user from seleted user list
    const RemoveUsers = (user) => {
        let selectUserLists = selectUserList.filter((elm) => {
            if(elm?.id !== user.id) {
                return elm;
            }
        })
        setSelectUserList(selectUserLists);
    };

    // select user type
    const SelectUserType = (e) => {
        setselectTypeUser(e.target.value)
    };

    useEffect(() => {
        if(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("add-chat")) {
            document.querySelector("div[contenteditable]").addEventListener("paste", function(e) {
                e.preventDefault();
                const text = e.clipboardData.getData("text/plain");
                document.execCommand("insertHTML", false, text);
            });
            RemovedEnterSpaceTextfield();
        }
    },[]);

    // shift inter insertLineBreak and only enter send message function
    const RemovedEnterSpaceTextfield = () => {
        const input = document.getElementById("messagFieldID");
        input?.addEventListener('keypress', (e) => {
            if (e.which === 13) e.preventDefault();   
            if (e.shiftkey && e.which === 13 ) document.execCommand('insertLineBreak');
            if (e.altKey && e.which === 13 ) document.execCommand('insertLineBreak');
        });
    };

    const onEnterAddBreakdown = (e) => {
        const keyCode = e.which || e.keyCode;
        if(keyCode === 13 && e.shiftKey || keyCode === 13 && e.altKey) {
            if(isOpenFireFoxBrowser !== true) {
                e.preventDefault();
                document.execCommand('insertLineBreak');
            } else {
                e.preventDefault();
                document.execCommand("insertHtml", false, " ");
                document.execCommand('insertParagraph',false, ''); 
            } 
        }
    };

    // send message function
    const sendMessageFnct = () => {
        const SelectedUserIds = [];
        selectUserList.forEach((elm) => {
            SelectedUserIds.push(elm?.id);
        });
        const currentmsghtml = document.getElementById("messagFieldID")?.innerHTML;
        const currentmsgtext = document.getElementById("messagFieldID")?.innerText;
        const currentmsghtmls = currentmsgtext !== ""  && currentmsghtml.replace(/<br>/g, '\n').trim();
        const currentmsgtexts = currentmsgtext.trim();
        setpicker(false);
        setSearchNotifications("");
        if(currentmsghtmls !== false && selectTypeUser !== "" && uploadFile === "") {
            setLoder(true);
            const jsonData1 = JSON.stringify({ 
                message: currentmsgtexts, 
                message_type: "text", 
                to_ids: selectTypeUser === "individualuser" ? SelectedUserIds.toString() : selectTypeUser, 
            });
            
            window.axios.post( ADD_CHAT, jsonData1, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                if (result.data) {
                    setTimeout(() => {
                        setSelectUserList([]);
                        sendSystemNotification(result.data.sys_notification_id);
                        toast.success("Message Sent Successfully...");
                        setLoder(false);
                    }, 500);
                }
            }).catch(function (result) {
                setTimeout(() => {
                    setLoder(false);
                    toast.error(result.response.data.error);
                }, 500);
            });
        } else if (uploadFile !== "" && currentmsghtmls === false && selectTypeUser !== "") {
            setLoder(true);
            const jsonData1 = JSON.stringify({ 
                message: uploadFile.id, 
                message_type: "file", 
                to_ids: selectTypeUser === "individualuser" ? SelectedUserIds.toString() : selectTypeUser, 
            });
            window.axios.post( ADD_CHAT, jsonData1, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                if (result.data) {
                    setTimeout(() => {
                        setSelectUserList([]);
                        sendSystemNotification(result.data.sys_notification_id);
                        toast.success("Message Sent Successfully...");
                        setLoder(false);
                    }, 500);
                }
            }).catch(function (result) {
                setTimeout(() => {
                    toast.error(result.response.data.error);
                }, 500);
            });
        } else {
            if(uploadFile !== "") {
                if(selectTypeUser === "") {
                    setTimeout(() => {
                        toast.error("Please select user type");
                    }, 200);
                } 
            } else {
                if(currentmsghtmls === false) {
                    setTimeout(() => {
                        toast.error("Please enter the message");
                    }, 200);
                } else if(selectTypeUser === "") {
                    setTimeout(() => {
                        toast.error("Please select user type");
                    }, 200);
                } 
            }
        };
        
        TextFieldMessageClear();
        if(selectTypeUser !== "") {
            setUploadFile("");
        }
    }; 

    // send notifications function
    const sendSystemNotification = (id) => {
        window.axios.get( SEND_SYSTEM_NOTIFICATION + id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setTimeout(() => {
                    setLoder(false);
                }, 500);
            }
        }).catch(function (result) {
            setTimeout(() => {
                console.log("Authentication credentials were not provided....");
            }, 500);
        });
    }

    // on enter message send fucntion
    const SendMessageOnchange = (e) => {
        const code = e.keycode || e.which;
        let cmntMsg = document.getElementById('messagFieldID').innerHTML;
        let textMsg = document.getElementById('messagFieldID').innerText;
        let cmntMsg_ = document.getElementById('messagFieldID');
        let textMsg_ = textMsg.trim(); //trim text input

        if (code === 13) {
            if (
              (cmntMsg != '' && textMsg_ == '') ||
              (cmntMsg == '' && textMsg_ != '')
            ) {
              sendMessageFnct();
              TextFieldMessageClear();
            } else if (cmntMsg != '' && textMsg_ != '') {
                sendMessageFnct();
                TextFieldMessageClear();
            } else {
                setTimeout(() => {
                  const child = cmntMsg_.lastElementChild;
                  while (child) {
                    cmntMsg_.removeChild(child);
                    child = cmntMsg_.lastElementChild;
                  }
                }, 100);
            }
        }
    };

    // send message textfield clear function
    const TextFieldMessageClear = () => {
        let cmntMsg = document.getElementById('messagFieldID');
        cmntMsg.innerHTML = null;
        setTimeout(() => {
            const child = cmntMsg.lastElementChild;
            while (child) {
                cmntMsg.removeChild(child);
                child = cmntMsg.lastElementChild;
            }
        }, 100);
    };

    // edit notifications functions
    const editNotifications = (elm) => {
        seteditNotification({
            ...editNotification,
            id: elm.id,
            message: elm.message,
        });
        setOpenModal(true);
        setPopuptitle({...popuptitle, title : "Edit Notification", buttonMain: "Save"});
        setTimeout(() => {
            // cursor focus contenteditable div
            const el = document.getElementById("addNotification");
            const selection = window.getSelection();
            const range = document.createRange();
            selection.removeAllRanges();
            range.selectNodeContents(el);
            range.collapse(false);
            selection.addRange(range);
            el.focus();
        }, 500);
    };

    useEffect(() => {
        const MessageField = document.getElementById("addNotification");
        if(editNotification.message !== "") {
            MessageField.innerHTML = editNotification.message;
        };
    }, [ editNotification ]);

    const sendNotificationMessage = (elm) => {
        setNotificationSelect(elm.message);
        setSelectTab("User_announcement");
    };

    useEffect(() => {
        const MessageField = document.getElementById("messagFieldID");
        if(NotificationSelect !== "") {
            MessageField.innerHTML = NotificationSelect;
            // cursor focus contenteditable div
            const selection = window.getSelection();
            const range = document.createRange();
            selection.removeAllRanges();
            range.selectNodeContents(MessageField);
            range.collapse(false);
            selection.addRange(range);
            MessageField.focus();
        };
    }, [ NotificationSelect ]);

    // send notification function
    const selectTosendNotification = (elm) => {
        var MessageField = document.getElementById("messagFieldID");
        setTimeout(() => {
            MessageField.innerHTML = elm.message;
            setNotificationSearchBox(false);
            setSearchNotifctionList([]);
        }, 200);
    };

    // add ntification
    const CreatNotification = () => {
        setOpenModal(!OpenModal);
        setPopuptitle({...popuptitle, title : "Add Notification", buttonMain: "Add"});
    };

    // delete notification
    const deleteNotifications = (elm) => {
        setOpenModal(!OpenModal);
        setDeleteId(elm.id);
        setPopuptitle({...popuptitle, title : "Delete Notification", buttonMain: "Delete"});
    };

    // select limit function
    const SelectLimit = (limits) => {
        setSelectUserLimit(limits);
        setCurrentPage(1);
    }

    return (
        <Layout>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header  border-0">
                        <div className='chatnotiftbtn'>
                            {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("add-chat")) && (<button className={selectTab === 'User_announcement' ? 'btn active' : 'btn'} onClick={() => setSelectTab("User_announcement")}>
                                <span className='tabsIcons User_announcement'></span>
                                User Announcement
                            </button>)}
                            {(ProfileData?.user_type?.includes("superuser") || ((ProfileData?.permission?.includes("notification-list") || ProfileData?.permission?.includes("add-notification") || ProfileData?.permission?.includes("update-notification") || ProfileData?.permission?.includes("delete-notification")))) && (<button className={selectTab === 'Notification_management' ? 'btn active' : 'btn'} onClick={() => setSelectTab("Notification_management")}>
                                <span className='tabsIcons Notification_management'></span>
                                Notification Management
                            </button>)}
                            {selectTab === 'Notification_management' && (<div className="notifctionbtnsearch">
                                <select defaultValue="Select Limit" className="col-6-form-select ms-3" onChange={(e) => SelectLimit(e.target.value)} aria-label="Default select example">
                                    <option value="Select Limit" >Select Limit</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                    <option value="60">60</option>
                                    <option value="70">70</option>
                                    <option value="80">80</option>
                                    <option value="80">90</option>
                                    <option value="100">100</option>
                                </select>
                                <input type="text" className="searchBoxsml" onChange={(e) => setNotificationSearch(e.target.value !== "" ? e.target.value : "none")} placeholder='Search notification'/>
                                {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("add-notification")) && (<button className='addnotifcation' onClick={() => CreatNotification()}>
                                    <img src={AddIcon} alt="add notification" />
                                    Add Notification
                                </button>)}
                            </div>)}
                        </div>
                    </div>
                    <div className='cardblogwrappr'>
                        {selectTab === "User_announcement" ? (<div className='row'>
                            <div className='col-md-12'>
                                <div className='searchBoxWrapper'>
                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <div className="coomforFiled selectuserCol">
                                                <div className="form-label">Search User :</div>
                                                <select defaultValue="Select User Types" className="form-select" onChange={(e) => SelectUserType(e)}>
                                                    <option value="" >Select User Types </option>
                                                    <option value="individualuser" >Individual Users</option>
                                                    <option value="superuser" >Superusers</option>
                                                    <option value="user" >User</option>
                                                    <option value="staff" >Staff Users</option>
                                                    <option value="operator" >Operator User</option>
                                                    <option value="software_vendor">Software Vendors</option>
                                                    <option value="marketing_vendor">Marketing Vendors</option>
                                                </select>
                                            </div>
                                        </div>
                                        {selectTypeUser === "individualuser" && (<React.Fragment>
                                            <div className='col-md-5'>
                                                <div className="coomforFiled">
                                                    <div className="form-label">&nbsp;</div>
                                                    <div className='formSearchBox'>
                                                        <input 
                                                            className="form-input" 
                                                            type="text" 
                                                            placeholder="Search User Name Or Mobile No" 
                                                            value={SearchValue} 
                                                            onChange={(e) => setSearchValue(e.target.value)} 
                                                        />
                                                        <button className='searchboxbtn' >
                                                            <img src={SearchIcon} alt="search button" />
                                                        </button>
                                                        {(openSearchBox && searchList.length > 0) && (<div className='searchlistWrapperlist'>
                                                            <ul>{searchList && searchList.map((elm, index) => {
                                                                    return(
                                                                        <li onClick={() => SelectSearchUser(elm)}  key={index}>
                                                                            <PreviewImage type="image" url={elm?.view_thumbnail_url} title={elm?.name} />
                                                                            <div className='userdetails'>
                                                                                <h4>{elm && elm?.name}</h4>
                                                                                <span>{elm?.phone}</span>
                                                                            </div>
                                                                            {selectUserList.map((elms, index) => elms?.id === elm?.id && <h5 className='seletduser' key={index}>Selected User</h5>)}
                                                                        </li>
                                                                    )
                                                            })}</ul>
                                                        </div>)}
                                                    </div>
                                                    {error && <span style={{ color: "red" }}>{error}</span>}
                                                </div>
                                            </div>
                                        </React.Fragment>)}
                                        <div className='col-md-5'>
                                            <div className="coomforFiled">
                                                <div className="form-label">&nbsp;</div>
                                                <div className='formSearchBox'>
                                                    <input 
                                                        className="form-input" 
                                                        type="text" 
                                                        placeholder="Search Notification..." 
                                                        value={SearchNotifications} 
                                                        onChange={(e) => setSearchNotifications(e.target.value)} 
                                                    />
                                                    <button className='searchboxbtn' >
                                                        <img src={SearchIcon} alt="search button" />
                                                    </button>

                                                    {(openNotificationSearchBox && searchNotifctionList.length > 0) && (<div className='searchlistWrapperlist notificatilistWrapperlist'>
                                                        <ul>{searchNotifctionList && searchNotifctionList.map((elm, index) => {
                                                                return(
                                                                    <li key={index} onClick={() => selectTosendNotification(elm)}>
                                                                        <div className='notificatinIconlist'>
                                                                            <img src={NotificationIcon} alt="Notification" />
                                                                        </div>
                                                                        <div className='userdetails'>
                                                                            <h4>{elm && elm?.message}</h4>
                                                                            <span>{timeSince(new Date(elm.updated_at))}</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                        })}</ul>
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {selectUserList.length > 0 && (<div className='row'>
                                        <div className='col-md-12'>
                                            <div className='selectuserwrapper'>
                                                <div className="form-label">Selected User :</div>
                                                {selectUserList && selectUserList.map((elm, index) => {
                                                    return(
                                                        <div className='userSelcchips' key={index}>
                                                        <PreviewImage type="image" url={elm?.view_thumbnail_url} title={elm?.name} />
                                                        <div className='userdetails'>{elm && elm?.name}</div>
                                                        <img src={CloseIcon} className="closechipsuser" onClick={() => RemoveUsers(elm)} alt="close" />
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                                <div className="row">
                                    <div className='messagewrappser'>
                                        <label className="form-label">Message :</label>
                                        <div className='sendMessgwraps'>
                                            <div 
                                                contentEditable={uploadFile === "" ? "true": "false"}
                                                className={uploadFile !== "" ? "messagField disable" : "messagField"} 
                                                id="messagFieldID" 
                                                placeholder="Type here…"
                                                onKeyPress={(e) =>setTimeout(()=> SendMessageOnchange(e), 10)}
                                                onKeyDown={(e) => onEnterAddBreakdown(e)}
                                                data-text="Type here…"
                                            ></div>
                                            <div className='emojiUplodfilewraps'>
                                                <div title='Emoji' className="emojibtn" onClick={() => setpicker(!picker)} >
                                                    <img src={EmojiImage} alt="emoji upload" />
                                                </div>
                                                <div title='Upload File' className='fileuploadbtn' onClick={() => setfileUploadBox(!fileUploadBox)}>
                                                    <img src={FileAttached} alt="file upload" />
                                                </div>
                                            </div>
                                            
                                            {fileUploadBox === true && (<UploadFilesBox setfileUploadBox={setfileUploadBox} setUploadFile={setUploadFile} accessToken={accessToken} />)}
                                            {picker === true && (<EmojiPicker id={"messagFieldID"} />)}
                                        </div>
                                    </div>
                                </div>
                                {uploadFile !== "" && (<div className='row'>
                                    <div className='col-md-12'>
                                        <div className='selectuserwrapper'>
                                            <div className="form-label">Uploaded File :</div>
                                            <div className='filesuploadewrapps'>
                                                <div className='filesuploded'>
                                                    {uploadFile?.name?.split('.').pop() === "jpg" || uploadFile?.name?.split('.').pop() === "jpeg" ||uploadFile?.name?.split('.').pop() === "png" || uploadFile?.name?.split('.').pop() === "gif" || uploadFile?.name?.split('.').pop() === "webp" || uploadFile?.name?.split('.').pop() === "PNG" || uploadFile?.name?.split('.').pop() === "svg" ? (
                                                        <PreviewImage type="imageupload" url={uploadFile?.view_thumbnail_url} title={uploadFile?.name} />
                                                    ) : <div className='iconfile'>{uploadFile?.name?.split(".")[1].toUpperCase()}</div>}
                                                    <div className='filedetails'>
                                                        <h4>{uploadFile?.name}</h4>
                                                        <span>{uploadFile?.size}</span>
                                                    </div>
                                                    <img src={CloseIcon} className="closechipsuser" onClick={() => setUploadFile("")} alt="close" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                                <div className="mb-3" style={{ marginTop: "20px" }}>
                                    <button type="submit" className="btn sendMessageBtn btn-primary" onClick={sendMessageFnct}>
                                        {loader && <span className="loadersendbtns"></span>}
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </div>) : (<div className='row'>
                            <div className='col-md-12'>
                                <div className="card-table table-responsive" style={{ padding: "0px" }}>
                                    <table className="table notificationstable table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Notification Title</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notifications && notifications.slice(0, selectUserLimit).map((elm, index) => {
                                                return(<tr key={index}>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>{((paginationLength.current_page - 1) * 10)+ index +1}</td>
                                                    <td>{elm.message}</td>
                                                    <td>{timeSince(new Date(elm.created_at))}</td>
                                                    <td>
                                                        <Tooltip content="Send Notification" direction="top">
                                                            <button type='button' className="tablesbtn" onClick={() => sendNotificationMessage(elm)} >
                                                                <img src={SendIcon} alt="send notification" />
                                                            </button>
                                                        </Tooltip>
                                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("update-notification")) && (<Tooltip content="Edit Notification" direction="top">
                                                            <button type='button' className="tablesbtn" onClick={() => editNotifications(elm)} >
                                                                <img src={EditIcon} alt="edit notification" />
                                                            </button>
                                                        </Tooltip>)}
                                                        {(ProfileData?.user_type?.includes("superuser") || ProfileData?.permission?.includes("delete-notification")) && (<Tooltip content="Delete Notification" direction="top">
                                                            <button type='button' className="tablesbtn" onClick={() => deleteNotifications(elm)} >
                                                                <img src={DeleteIcon} alt="delete notification" />
                                                            </button>
                                                        </Tooltip>)}
                                                    </td>
                                                </tr>)
                                            })}
                                            {notifications && notifications.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={4}>No notification found</td></tr>}
                                        </tbody>
                                    </table>
                                </div>  
                                                 
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>

            {selectTab === 'Notification_management' && <React.Fragment>{paginationLength.total_records > 10 && (<div className='paginationCustom'>
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={paginationLength?.record_limit}
                    onPageChange={(pageNumber) => currentFunction(pageNumber)}
                    totalItems={paginationLength && paginationLength.total_records}
                    pageNeighbours={2}
                />
            </div>)}</React.Fragment>}  
            
            <div className="col-12">
                <div className="card card-md">
                    <div className="card-stamp card-stamp-lg">
                        <div className="card-stamp-icon bg-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7" /><line x1="10" y1="10" x2="10.01" y2="10" /><line x1="14" y1="10" x2="14.01" y2="10" /><path d="M10 14a3.5 3.5 0 0 0 4 0" /></svg>
                        </div>
                    </div>
                </div>
            </div>

            {OpenModal && (<CommonPopup 
                editNotification={editNotification}
                seteditNotification={seteditNotification}
                setOpenModal={setOpenModal}
                title={popuptitle.title} 
                submitbuttontitle={popuptitle.buttonMain}
                DeleteId={DeleteId}
                setPaginationLength={setPaginationLength}
            />)}
            {OpenModal && (<div className='modalBckground' onClick={() => setOpenModal(false)}></div>)}
        </Layout>
    )
}
export default Chat;