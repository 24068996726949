/* eslint-disable */
import React, { useEffect } from 'react';
import Layout from '../Shared/Layout';
import { COUNT_API_URL } from '../Shared/Constant';
import { activityUserCountList } from '../../redux/actions';
import { useSelector, useDispatch} from 'react-redux';
import UserImage from "../../assets/img/user.png";
import GroupsImage from "../../assets/img/groups.png";
import GroupsChatImage from "../../assets/img/Groups_chat.png";
import ChatImage from "../../assets/img/chat.png";

const Dashboard = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const DashBoardCountsList = useSelector((state) => state.allReducers.dashboardCountsList);

    useEffect(() => {
        let currentDate = new Date();
        const cdate = currentDate.toISOString().split('T')[0]
        window.axios.get(COUNT_API_URL + cdate, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(activityUserCountList(result.data.data));
            }
        }).catch(function (result) {
        });
    }, []);
    
    return (
        <Layout>
            <div className="col-sm-6 col-lg-3">
                <div className="card card_blogsset">
                    <div className="card-body totlUserBlog">
                        <div className='Dashboardblosgswr'>
                            <div className='dashblogtext'>
                                <h4>Total Users <span className="subheader">( Total )</span></h4>
                                <h1>{DashBoardCountsList?.total_counts?.total_users}</h1>
                            </div>
                            <div className='dashbrImg' style={{ backgroundColor: "#4D4CAC" }}>
                                <img src={UserImage} alt="Total Users" />
                            </div>
                        </div>
                        <div className='progressbar'>
                            <div className="d-flex mb-2">
                                <div>Conversion rate</div>
                                <div className="ms-auto">
                                    <span className={DashBoardCountsList?.percentage_comparison?.users < 0 ? "text-red d-inline-flex align-items-center lh-1" : "text-green d-inline-flex align-items-center lh-1"}>
                                        {Math.abs(DashBoardCountsList?.percentage_comparison?.users)}%
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"
                                            style={{
                                                transform: DashBoardCountsList?.percentage_comparison?.users < 0 ? "rotate(180deg)" : "none",
                                            }}
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="3 17 9 11 13 15 21 7" /><polyline points="14 7 21 7 21 14" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="progress progress-sm">
                                <div style={{ width: Math.abs(DashBoardCountsList?.percentage_comparison?.users) + '%' }} className={DashBoardCountsList?.percentage_comparison?.users < 0 ? "progress-bar bg-danger w-" + DashBoardCountsList?.percentage_comparison?.users : "progress-bar bg-primary w-" + DashBoardCountsList?.percentage_comparison?.users} role="progressbar" aria-valuenow={DashBoardCountsList?.percentage_comparison?.users} aria-valuemin="0" aria-valuemax="100" aria-label={DashBoardCountsList?.percentage_comparison?.users + "% Complete"}>
                                    <span className="visually-hidden">{DashBoardCountsList?.percentage_comparison?.users}% Complete</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3">
                <div className="card card_blogsset">
                    <div className="card-body totlGroupBlog">
                        <div className='Dashboardblosgswr'>
                            <div className='dashblogtext'>
                                <h4>Groups <span className="subheader">( Total )</span></h4>
                                <h1>{DashBoardCountsList?.total_counts?.total_groups}</h1>
                            </div>
                            <div className='dashbrImg' style={{ backgroundColor: "#FF808B" }}>
                                <img src={GroupsImage} alt="Total Users" />
                            </div>
                        </div>
                        <div className='progressbar'>
                            <div className="d-flex mb-2">
                                <div>Conversion rate</div>
                                <div className="ms-auto">
                                    <span className={DashBoardCountsList?.percentage_comparison?.groups < 0 ? "text-red d-inline-flex align-items-center lh-1" : "text-green d-inline-flex align-items-center lh-1"}>
                                        {Math.abs(DashBoardCountsList?.percentage_comparison?.groups)}%
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"
                                            style={{
                                                transform: DashBoardCountsList?.percentage_comparison?.groups < 0 ? "rotate(180deg)" : "none",
                                            }}
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="3 17 9 11 13 15 21 7" /><polyline points="14 7 21 7 21 14" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="progress progress-sm">
                                <div style={{ width: Math.abs(DashBoardCountsList?.percentage_comparison?.groups) + '%' }} className={DashBoardCountsList?.percentage_comparison?.groups < 0 ? "progress-bar bg-danger w-" + DashBoardCountsList?.percentage_comparison?.groups : "progress-bar bg-primary w-" + DashBoardCountsList?.percentage_comparison?.groups} role="progressbar" aria-valuenow={DashBoardCountsList?.percentage_comparison?.groups} aria-valuemin="0" aria-valuemax="100" aria-label={DashBoardCountsList?.percentage_comparison?.groups + "% Complete"}>
                                    <span className="visually-hidden">{DashBoardCountsList?.percentage_comparison?.groups}% Complete</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3">
                <div className="card card_blogsset">
                    <div className="card-body UseChatBlog">
                        <div className='Dashboardblosgswr'>
                            <div className='dashblogtext'>
                                <h4>Users Chat <span className="subheader">( Total )</span></h4>
                                <h1>{DashBoardCountsList?.total_counts?.total_user_chats}</h1>
                            </div>
                            <div className='dashbrImg userchat' style={{ backgroundColor: "#389FE1" }}>
                                <img src={ChatImage} alt="Total Users" />
                            </div>
                        </div>
                        <div className='progressbar'>
                            <div className="d-flex mb-2">
                                <div>Conversion rate</div>
                                <div className="ms-auto">
                                    <span className={DashBoardCountsList?.percentage_comparison?.user_chats < 0 ? "text-red d-inline-flex align-items-center lh-1" : "text-green d-inline-flex align-items-center lh-1"}>
                                        {Math.abs(DashBoardCountsList?.percentage_comparison?.user_chats)}
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"
                                            style={{
                                                transform: DashBoardCountsList?.percentage_comparison?.user_chats < 0 ? "rotate(180deg)" : "none",
                                            }}
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="3 17 9 11 13 15 21 7" />
                                            <polyline points="14 7 21 7 21 14" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="progress progress-sm">
                                <div style={{ width: Math.abs(DashBoardCountsList?.percentage_comparison?.user_chats)}} className={DashBoardCountsList?.percentage_comparison?.user_chats < 0 ? "progress-bar bg-danger w-" + DashBoardCountsList?.percentage_comparison?.user_chats : "progress-bar bg-primary w-" + DashBoardCountsList?.percentage_comparison?.user_chats} role="progressbar" aria-valuenow={DashBoardCountsList?.percentage_comparison?.user_chats} aria-valuemin="0" aria-valuemax="100" aria-label={DashBoardCountsList?.percentage_comparison?.user_chats + "% Complete"}>
                                    <span className="visually-hidden">{DashBoardCountsList?.percentage_comparison?.user_chats}% Complete</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3">
                <div className="card card_blogsset">
                    <div className="card-body GroupChatBlog">
                        <div className='Dashboardblosgswr'>
                            <div className='dashblogtext'>
                                <h4>Groups Chat <span className="subheader">( Total )</span></h4>
                                <h1>{DashBoardCountsList?.total_counts?.total_group_chats}</h1>
                            </div>
                            <div className='dashbrImg groupchat' style={{ backgroundColor: "#FA8E33" }}>
                                <img src={GroupsChatImage} alt="Total Users" />
                            </div>
                        </div>
                        <div className='progressbar'>
                            <div className="d-flex mb-2">
                                <div>Conversion rate</div>
                                <div className="ms-auto">
                                    <span className={DashBoardCountsList?.percentage_comparison?.group_chats < 0 ? "text-red d-inline-flex align-items-center lh-1" : "text-green d-inline-flex align-items-center lh-1"}>
                                        {Math.abs(DashBoardCountsList?.percentage_comparison?.group_chats)}%
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"
                                            style={{
                                                transform: DashBoardCountsList?.percentage_comparison?.group_chats < 0 ? "rotate(180deg)" : "none",
                                            }}
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="3 17 9 11 13 15 21 7" /><polyline points="14 7 21 7 21 14" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="progress progress-sm">
                                <div style={{ width: Math.abs(DashBoardCountsList?.percentage_comparison?.group_chats) }} className={DashBoardCountsList?.percentage_comparison?.group_chats < 0 ? "progress-bar bg-danger w-" + DashBoardCountsList?.percentage_comparison?.group_chats : "progress-bar bg-primary w-" + DashBoardCountsList?.percentage_comparison?.group_chats} role="progressbar" aria-valuenow={DashBoardCountsList?.percentage_comparison?.group_chats} aria-valuemin="0" aria-valuemax="100" aria-label={DashBoardCountsList?.percentage_comparison?.group_chats + "% Complete"}>
                                    <span className="visually-hidden">{DashBoardCountsList?.percentage_comparison?.group_chats}% Complete</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header border-0">
                        <div className="card-title">User activity</div>
                    </div>
                    <div className="card-table table-responsive">
                        <table className="table table-vcenter">
                            <thead>
                                <tr>
                                    <th>Register User Count</th>
                                    <th>Register Group Count</th>
                                    <th>Users Chat Count</th>
                                    <th>Groups Chat Count</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DashBoardCountsList?.count_list && DashBoardCountsList?.count_list?.length > 0 && DashBoardCountsList.count_list.map(({ registered_users, registered_groups, user_chats, group_chats, date }, index) => (
                                    <tr key={index}>
                                        <td>
                                            {registered_users}
                                        </td>
                                        <td>
                                            {registered_groups}
                                        </td>
                                        <td>
                                            {user_chats}
                                        </td>
                                        <td>
                                            {group_chats}
                                        </td>
                                        <td className="text-nowrap text-muted">{date}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-md">
                    <div className="card-stamp card-stamp-lg">
                        <div className="card-stamp-icon bg-primary">

                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7" /><line x1="10" y1="10" x2="10.01" y2="10" /><line x1="14" y1="10" x2="14.01" y2="10" /><path d="M10 14a3.5 3.5 0 0 0 4 0" /></svg>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard;