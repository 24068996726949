/*eslint-disable */
import React, { useEffect, useState } from 'react';
import Layout from '../Shared/Layout';
import { INVOICE_LIST, CUSTOMER_LIST } from '../Shared/Constant';
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
import { getInvoiceList } from "../../redux/actions/index";
import { useSelector, useDispatch} from 'react-redux';
import PreviewIcon from "../../assets/img/preview.png";
import Tooltip from '../Common/tooltip';
import InvoicePreviewPopup from '../Common/InvoicePreviewPopup';

const Invoice = (props) => {
    const { invoiceid, from } = props;
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectUserLimit, setSelectUserLimit ] = useState(10);
    const dispatch = useDispatch();
    const Invoicelist = useSelector((state) => state.allReducers.invoicelist);
    const accessToken = useSelector((state) => state.allReducers.accessToken);
    const ProfileData = useSelector((state) => state.allReducers.profileDetails);
    const [ UserListSelect, setUserListSelect ] = useState("all");
    const [ SearchBox, setSearchBox ] = useState("");
    const [ SelectCustomer, setSelectCustomer ] = useState("none");
    const [ popupData, setPopupData ] = useState({
        openpopup: false,
        title: "",
        payload: "",
        previewwith: ""
    });
    const [ searchList, setSearchList ] = useState([]);
    const [ openSearchBox, setopenSearchBox ] = useState(false);

    useEffect(() => {
        userFilter("all", 1, selectUserLimit);
        if(SearchBox === "") {
            if(invoiceid === undefined) {
                setSelectCustomer("none");
            } else {
                setSelectCustomer(invoiceid);
            }
        }
    }, [selectUserLimit, UserListSelect, SelectCustomer, SearchBox, invoiceid]);

    const currentFunction = (pageNumber) => {
        return setCurrentPage(pageNumber, userFilter("all", pageNumber, selectUserLimit));      
    };

    const userFilter = (type, currentPages, limit) => {       
        window.axios.get(INVOICE_LIST+`${UserListSelect}/${limit}/${currentPages}/1/${SelectCustomer}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(getInvoiceList(result?.data?.data))
                setPaginationLength(result.data.pagination);
            }
        }).catch(function (error) {
            if(error) {
                dispatch(getInvoiceList([]))
            }
        });
    }

    const UserListFilter = (type) => {
        setUserListSelect(type);
        setCurrentPage(1);
    }

    const SelectLimit = (limits) => {
        setSelectUserLimit(limits);
        setCurrentPage(1);
    }

    // invoice payload preview
    const InvoicePyloadPreview = (payload, data) => {
        setPopupData({
            ...popupData,
            openpopup: !popupData.openpopup,
            title: "Bill To",
            payload: JSON.parse(payload),
            previewwith: data
        });
    };

    useEffect(() => {
        SearchUserList();
        if(SearchBox === "") {
            setopenSearchBox(false);
        };
    }, [SearchBox]);

    // search customer list
    const SearchUserList = () => {
        if(SearchBox) {
            window.axios.get(CUSTOMER_LIST+`5000/1`+ `/${SearchBox}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(function (result) {
                if (result.data) {
                    setSearchList(result.data.data);
                    setopenSearchBox(true);
                }
            }).catch(function (result) {
                setSearchList([])
            });
        }
    };

    // select customer 
    const SelectCustomerUser = (customer) => {
        setSelectCustomer(customer?.id);
        setopenSearchBox(false);
    };

    return (
        <Layout>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header border-0">
                        <div className="card-title">Invoice list :</div>
                        <div className='cradRightsection'>
                            <input type="text" className="form-filter ms-3" value={SearchBox} onChange={(e) => setSearchBox(e.target.value)} placeholder='Search Customer Details'/>
                            {(openSearchBox ) && (<div className='searchlistWrapperlist customerlistWrapperlist'>
                                <ul>{searchList.length > 0 ? searchList && searchList.map((elm, index) => {
                                    return(
                                        <li onClick={() => SelectCustomerUser(elm)}  key={index}>
                                            <div className='userdetails customersearch'>
                                                <h4><div>{elm && elm?.customer_name}</div> <span>( {elm?.company_name} )</span></h4>
                                                <span>{elm?.phone} / {elm?.email}</span>
                                            </div>
                                        </li>
                                    )
                                }) : <li>Customer details not found</li>}</ul>
                            </div>)}
                            <select defaultValue="all" className="col-6-form-select ms-3" onChange={(e) => UserListFilter(e.target.value)} aria-label="Default select example" >
                                <option value="all" >All Invoice</option>
                                <option value="paid" >Paid</option>
                                <option value="unpaid" >Unpaid</option>
                            </select>
                            <select defaultValue="Select Limit" className="col-6-form-select ms-3" onChange={(e) => SelectLimit(e.target.value)} aria-label="Default select example">
                                <option value="Select Limit" >Select Limit</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="80">90</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    <div className="card-table table-responsive">
                        <table className="table tableInvoice table-vcenter">
                            <thead>
                                <tr>
                                    <th>Invoice Name</th>
                                    {(invoiceid === undefined && from === undefined) && <th>Billing Address</th>}
                                    {(invoiceid === undefined && from === undefined) && <th>Customer Details</th>}
                                    <th>Amount Paid</th>
                                    <th>Amount Remaining</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    {ProfileData?.permission?.includes("view-invoice") && (<>{(invoiceid === undefined && from === undefined) && <th>Actions</th>}</>)}
                                </tr>
                            </thead>
                            <tbody>
                                {Invoicelist && Invoicelist.length > 0 ? Invoicelist.map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>
                                            <span className='custmeridhighlight' onClick={() => InvoicePyloadPreview(elm.item_payload, elm)}>{elm.invoice_name}</span>
                                        </td>
                                        {(invoiceid === undefined && from === undefined) && <td dangerouslySetInnerHTML={{__html: elm.billing_address}}></td>}
                                        {(invoiceid === undefined && from === undefined) && <td>
                                            <ul className="customer_details">
                                                <li>
                                                    <Tooltip content="Customer Name" direction="top">
                                                        {elm.customer_details.customer_name}
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip content="Company Name" direction="top">
                                                        {elm.customer_details.company_name}
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip content="Phone" direction="top">
                                                        {elm.customer_details.phone}
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip content="Email" direction="top">
                                                        {elm.customer_details.email}
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </td>}
                                        <td>{elm.amount_paid}</td>
                                        <td>{elm.amount_remaining}</td>
                                        <td>{elm.due_date}</td>
                                        <td>{elm.is_paid === "False" ? <span className='badge bg-red-lt'>Pending</span> : <span className='badge bg-green-lt'>Paid</span>}</td>
                                        {ProfileData?.permission?.includes("view-invoice") && (<>{(invoiceid === undefined && from === undefined) && <td>
                                            <Tooltip content="View Invoice" direction="top">
                                                <button type='button' className="tablesbtn previewIcon" onClick={() => InvoicePyloadPreview(elm.item_payload, elm)} >
                                                    <img src={PreviewIcon} alt="View Invoice" />
                                                </button>
                                            </Tooltip>
                                        </td>}</>)}
                                    </tr>)
                                }) : (<tr>
                                    <td>No data found</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div className='paginationCustom'>
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={paginationLength?.record_limit}
                    onPageChange={(pageNumber) => currentFunction(pageNumber)}
                    totalItems={paginationLength?.total_records}
                    pageNeighbours={2}
                />
            </div>
            
            <div className="col-12">
                <div className="card card-md">
                    <div className="card-stamp card-stamp-lg">
                        <div className="card-stamp-icon bg-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7" /><line x1="10" y1="10" x2="10.01" y2="10" /><line x1="14" y1="10" x2="14.01" y2="10" /><path d="M10 14a3.5 3.5 0 0 0 4 0" /></svg>
                        </div>
                    </div>
                </div>
            </div>

            {popupData.openpopup && (<InvoicePreviewPopup setPopupData={setPopupData} popupData={popupData} />)}
            {popupData.openpopup && (<div className='modalBckground' onClick={() => setPopupData({...popupData, openpopup: false})}></div>)}
        </Layout>
    )
}

export default Invoice;