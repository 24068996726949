import React from "react";
import CloseIcon from "../../assets/img/closebutton.png";

const InvoicePreviewPopup = (props) => {
    const { popupData, setPopupData } = props;

    return(
        <div className="invoicpreviewpopup">
            <div className="invoicpreviewpopupheader">
                <img src={CloseIcon} onClick={() => setPopupData({...popupData, openpopup: false})} alt="close popup" />
                <div className="invoiceid">
                    <span>Invoice #</span>
                    <div>{popupData?.previewwith?.invoice_name}</div>
                </div>
                <h4>{popupData?.title}</h4>
                <p>{popupData?.previewwith?.invoice_from}</p>
            </div>
            <div className="invoicpreviewpopupbody">
                <h5>Description</h5>
                <ul>{popupData?.payload.map((elm, index) => {
                    return(<li key={index}>
                        <div className="Descriphead">
                            <h4>{elm.desc}</h4> 
                            <div>{elm.amount}</div>
                        </div>
                        <div className="discrcolswraps">
                            <div className="discrcols">
                                <span>Intake</span>
                                {elm?.intake !== "" ? elm?.intake : "-"}
                            </div>
                            <div className="discrcols">
                                <span>Redeem</span>
                                {elm?.redeem !== "" ? elm?.redeem : "-"}
                            </div>
                            <div className="discrcols">
                                <span>Hold</span>
                                {elm?.hold !== "" ? elm?.hold : "-"}
                            </div>
                            <div className="discrcols">
                                <span>License Fee</span>
                                {elm?.fee !== "" ? elm?.fee : "-"}
                            </div>
                        </div>
                    </li>)})}
                </ul>
                <div className="totalinvoce">
                    <h4>Total Amount :</h4>
                    <h3>$ {popupData?.payload.reduce((total, thing) => total + thing.amount, 0)}</h3>
                </div>
            </div>
        </div>
    )
};

export default InvoicePreviewPopup;